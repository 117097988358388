@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-BoldItalic.eot');
  src: local('Gotham Pro Bold Italic'), local('GothamPro-BoldItalic'),
  url('../fonts/GothamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-BoldItalic.woff') format('woff'),
  url('../fonts/GothamPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Italic.eot');
  src: local('Gotham Pro Italic'), local('GothamPro-Italic'),
  url('../fonts/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-Italic.woff') format('woff'),
  url('../fonts/GothamPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Medium.eot');
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
  url('../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-Medium.woff') format('woff'),
  url('../fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-BlackItalic.eot');
  src: local('Gotham Pro Black Italic'), local('GothamPro-BlackItalic'),
  url('../fonts/GothamPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-BlackItalic.woff') format('woff'),
  url('../fonts/GothamPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Bold.eot');
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
  url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-Bold.woff') format('woff'),
  url('../fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Narrow';
  src: url('../fonts/GothamProNarrow-Bold.eot');
  src: local('Gotham Pro Narrow Bold'), local('GothamProNarrow-Bold'),
  url('../fonts/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamProNarrow-Bold.woff') format('woff'),
  url('../fonts/GothamProNarrow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Narrow';
  src: url('../fonts/GothamProNarrow-Medium.eot');
  src: local('Gotham Pro Narrow Medium'), local('GothamProNarrow-Medium'),
  url('../fonts/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamProNarrow-Medium.woff') format('woff'),
  url('../fonts/GothamProNarrow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-LightItalic.eot');
  src: local('Gotham Pro Light Italic'), local('GothamPro-LightItalic'),
  url('../fonts/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-LightItalic.woff') format('woff'),
  url('../fonts/GothamPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Light.eot');
  src: local('Gotham Pro Light'), local('GothamPro-Light'),
  url('../fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-Light.woff') format('woff'),
  url('../fonts/GothamPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Black.eot');
  src: local('Gotham Pro Black'), local('GothamPro-Black'),
  url('../fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-Black.woff') format('woff'),
  url('../fonts/GothamPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'),
  url('../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro.woff') format('woff'),
  url('../fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-MediumItalic.eot');
  src: local('Gotham Pro Medium Italic'), local('GothamPro-MediumItalic'),
  url('../fonts/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-MediumItalic.woff') format('woff'),
  url('../fonts/GothamPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('../fonts/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-LightIt.eot');
  src: url('../fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-LightIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-LightIt.woff') format('woff'),
  url('../fonts/ProximaNova-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Black.eot');
  src: url('../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Black.woff2') format('woff2'),
  url('../fonts/ProximaNova-Black.woff') format('woff'),
  url('../fonts/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Semibold.eot');
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-RegularIt.eot');
  src: url('../fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-RegularIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-RegularIt.woff') format('woff'),
  url('../fonts/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-ExtrabldIt.eot');
  src: url('../fonts/ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-ExtrabldIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-ExtrabldIt.woff') format('woff'),
  url('../fonts/ProximaNova-ExtrabldIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Light.eot');
  src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Light.woff2') format('woff2'),
  url('../fonts/ProximaNova-Light.woff') format('woff'),
  url('../fonts/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-BlackIt.eot');
  src: url('../fonts/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-BlackIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-BlackIt.woff') format('woff'),
  url('../fonts/ProximaNova-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-SemiboldIt.eot');
  src: url('../fonts/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-SemiboldIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-SemiboldIt.woff') format('woff'),
  url('../fonts/ProximaNova-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-ThinIt.eot');
  src: url('../fonts/ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-ThinIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-ThinIt.woff') format('woff'),
  url('../fonts/ProximaNova-ThinIt.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-BoldIt.eot');
  src: url('../fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-BoldIt.woff2') format('woff2'),
  url('../fonts/ProximaNova-BoldIt.woff') format('woff'),
  url('../fonts/ProximaNova-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Extrabld.eot');
  src: url('../fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Extrabld.woff2') format('woff2'),
  url('../fonts/ProximaNova-Extrabld.woff') format('woff'),
  url('../fonts/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('../fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNovaT-Thin.eot');
  src: url('../fonts/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
  url('../fonts/ProximaNovaT-Thin.woff') format('woff'),
  url('../fonts/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Cn';
  src: url('../fonts/ProximaNovaCond-Regular.eot');
  src: url('../fonts/ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNovaCond-Regular.woff2') format('woff2'),
  url('../fonts/ProximaNovaCond-Regular.woff') format('woff'),
  url('../fonts/ProximaNovaCond-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}