.wrapper.wrapper-login{
  padding: 20px;
  min-height: 100vh;
  position: relative;

  @media (max-width: 991px) {
    padding: 13px 0 0;

    &:after{
      @include pseudo();
      top: 13px;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 20px;
      background: linear-gradient(180deg, #303030 -22.74%, #222222 62.36%);
      z-index: -1;
    }
    &:before{
      @include pseudo();
      top: 0;
      left: 14px;
      right: 14px;
      bottom: 0;
      border-radius: 20px;
      background: #303030;
      z-index: -1;
    }

    .custom-control {
      font-size: 13px;
      line-height: 26px;
    }
  }

  .form-control{
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 17px;
    margin-top: -.97rem;
    &__label {
      font-size: 14px;
      margin-bottom: 24px;
    }
    &__field-icon{
      right: 0;
    }
    &__code {
      background: #2C2C2C;
      .form-control--code{
        width: calc(100% / 3);
        &+.form-control--code {
          border-color: #353535;
        }
      }
    }

    &__captcha{
      .form-control__field-icon{
        right: 19px;
        width: 18px;
        height: 18px;
        margin-top: -9px;
        color: $primary;
        cursor: pointer;
      }
    }
    
    &.form-control--captcha{
      background-color: #353535 !important;
      border-radius: 5px;
      font-size: 15px;
      padding-left: 25px;
      padding-right: 25px;
      border: 0;

      &.form-control--captcha__img{
        padding-right: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;

        img{
          display: block;
          height: 100%;
          pointer-events: none;
          user-select: none;
        }
      }
    }

  }
  .alert{
    font-size: 15px;
    &-danger{
      color: #fff;
      background: $pink;
      border-color: $pink;
    }
    &-success{
      color: #fff;
      background: $teal;
      border-color: $teal;
    }
  }

}

@keyframes wrapperLogin {
  0%{
    bottom: -100%;
    transform: scale(1) rotate(0deg);
  }
  50%{
    bottom: -120%;
    transform: scale(1.2) rotate(15deg);
  }
  100%{
    bottom: -100%;
    transform: scale(1) rotate(0deg);
  }
}

.wrapper-login{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .container{
    padding-top: 76px;
    padding-bottom: 76px;
  }
  &__decor{
    position: fixed;
    top: 20px;
    right: 20px;
    bottom: 20px;
    background: $bg-light;
    text-align: center;
    border-radius: 30px;
    padding: 76px;
    width: 44%;
    overflow: hidden;

    &-img{
      top: 40%;
      left: -10%;
      right: -10%;
      bottom: -100%;
      position: absolute;
      display: block;
      color: #1B1B1B;

      .icon{
        display: block;
        width: 100%;
        height: auto;
        animation: wrapperLogin 12s linear infinite;
        will-change: transform;

        path{
          fill: #1B1B1B !important;
          transition: $transition-base !important;
        }
        path:hover{
          fill: $primary !important;
        }
      }
    }

    &-logo{
      font-family: 'Proxima Nova Cn', sans-serif;
      display: block;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 22px;
      margin-bottom: 55px;
      position: relative;

      .icon{
        width: 36px;
        height: 36px;
        fill: $primary;
        margin-right: 10px;
      }
    }
    &-title{
      display: block;
      color: $color-light;
      font-weight: 700;
      font-size: 26px;
      line-height: 1;
      margin-bottom: 25px;
      position: relative;
    }
    &-subtitle{
      display: block;
      font-size: 15px;
      color: $color-light;
      position: relative;
    }
  }

  &__form{
    max-width: 40%;

    .alert{
      margin-bottom: 50px;
      margin-top: -25px;
    }

    &-title{
      display: block;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 70px;
      font-family: $headings-font-family;
      line-height: 1;

      span{
        color: $color-light;

        &.wrapper-login__form-subtitle{
          display: block;
          font-size: 17px;
          line-height: 16px;
          margin-top: 25px;
          font-weight: 400;
          font-family: $font-family-sans-serif;
        }
      }
    }

    .form-row{
      margin: -25px;

      .col{
        padding: 25px;
      }
    }
    &-mobile,&-phone{
      display: none;
    }
  }

  &__line{
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &-txt{
      padding: 0 20px;
    }

    &:before,&:after{
      content: '';
      display: inline-block;
      vertical-align: top;
      height: 1px;
      flex-grow: 1;
      background: $input-border;
    }
  }

  &__close{
    position: absolute;
    width: 54px;
    height: 54px;
    background: $primary;
    border-radius: 50%;
    padding: 17px;
    color: rgba(#fff,.5);
    z-index: 9;
    top: 30px;
    right: 30px;

    .icon{
      width: 100%;
      height: 100%;
      display: block;
    }

    &:hover{
      color: #fff;
    }
  }
  
  @media (max-width: 991px) {
    justify-content: flex-start;
    .container{
      flex-grow: 1;
      padding-top: 20px;
      padding-bottom: 60px;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      flex-direction: column;
    }
    &__decor{
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      background: none;
      text-align: center;
      border-radius: 0;
      padding: 30px;
      width: 100%;
      height: auto;
      overflow: hidden;

      &-img, &-logo,&-title,&-subtitle{
        display: none;
      }
    }
    &__close{
      position: relative;
      width: 40px;
      height: 40px;
      background: $input-border;
      padding: 14px;
      color: $red;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover{
        color: #fff;
      }
    }
    &__form{
      max-width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      &-title{
        font-size: 40px;
        font-weight: 700;
        max-width: 260px;
        line-height: 1.2;

        span.wrapper-login__form-subtitle {
          line-height: 1.25;
        }
      }

      form{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      &-desktop{
        display: none;
      }
      &-phone{
        display: block;
      }
      &-mobile{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: center;

        .link-light{
          margin-top: 50px;
        }

        .btn-outline-primary{
          margin-top: 35px;
        }

        .link-login{
          margin-bottom: auto;
        }
      }
    }
    &__mobile{
      text-align: center;
      &-img{
        padding: 3.9vh 0;
        position: relative;
        margin-bottom: 6.7vh;
        display: block;
        &:before{
          @include pseudo();
          top: 0;
          bottom: 0;
          left: 25px;
          right: 25px;
          background: #303030;
          border-radius: 15px;
          z-index: -1;
        }
        &-item{
          position: relative;
          width: 100%;
          height: 21.2vh;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url(../img/bg/login_mobile.svg) no-repeat center;
          background-size: cover;
          color: $primary;
          box-shadow: 0px 5px 20px 3px rgba(48, 48, 48, 0.2);
          border-radius: 15px;

          .icon{
            width: 8vh;
            height: 8vh;
          }
        }
      }
      &-title{
        font-weight: 800;
        font-size: 32px;
        font-family: $headings-font-family;
        margin-bottom: 15px;
        display: block;
        line-height: 1.25;
        span{
          color: $primary;
        }
      }
      &-subtitle{
        display: block;
        font-size: 16px;
        color: $color-light;
        line-height: 1.5;
      }
      &-buttons{
        margin-top: 11.8vh;
        display: flex;
        flex-direction: column;

        .btn{
          width: 100%;

          &+.btn{
            margin-top: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 413px) {
    &__mobile{
      &-title{
        font-size: 30px;
      }
    }
  }
  @media (max-width: 374px) {
    &__mobile{
      &-title{
        font-size: 26px;
      }
    }
  }
}

.wrapper.wrapper-blocked{
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  @media (max-width: 991px) {
    padding: 0;
  }

  .form-control__field-icon{
    right: 0;
  }
  .form-control{
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: 17px;
    margin-top: -.97rem;
  }
  .alert{
    font-size: 15px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .alert-danger{
    color: #fff;
    background: $pink;
    border-color: $pink;
  }
  .alert-success{
    color: #fff;
    background: $teal;
    border-color: $teal;
  }
}
.wrapper-blocked{
  &__content{
    flex: 1 0 auto;
    background: $bg-light;
    border-radius: 30px;
    padding: 8vh 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    form{
      max-width: 636px;
      margin: 0 auto;
    }

    &-icon{
      display: block;
      margin: 0 auto 50px;
      width: 130px;
      height: 130px;

      .icon{
        width: 100%;
        height: 100%;
      }
    }

    &-title{
      display: block;
      margin-bottom: 50px;
      font-size: 48px;
      font-weight: 700;
      font-family: $headings-font-family;
      line-height: 1;
    }

    .alert{
      text-align: center;
      margin-bottom: calc(50px - .75rem);
    }

    .form-row{
      margin: -25px;

      .col{
        padding: 25px;
      }
    }
  }

  @media (max-width: 991px) {
    &__content{
      padding: 60px 0;
      border-radius: 0;
      form{
        max-width: 100%;
      }

      &-icon{
        width: 80px;
        height: 80px;
      }

      &-title {
        font-size: 30px;
      }
    }
  }
}

.wrapper.wrapper-registration{
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  @media (max-width: 991px) {
    padding: 0;
  }
  .alert{
    font-size: 15px;
  }
  .alert-danger{
    color: #fff;
    background: $pink;
    border-color: $pink;
  }
  .alert-success{
    color: #fff;
    background: $teal;
    border-color: $teal;
  }
}
.wrapper-registration{
  &__content{
    flex: 1 0 auto;
    background: $primary;
    border-radius: 30px;
    padding: 8vh 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    form{
      max-width: 752px;
      margin: 0 auto;
    }

    &-icon{
      display: block;
      margin: 0 auto 50px;
      width: 230px;
      height: 230px;
      max-height: 25vh;

      .icon{
        width: 100%;
        height: 100%;
      }
    }

    &-title{
      display: block;
      margin-bottom: 36px;
      font-size: 48px;
      font-weight: 700;
      font-family: $headings-font-family;
      line-height: 1.2;

      span{
        opacity: .5;
        display: block;
      }
    }

    .alert{
      text-align: center;
      margin-bottom: 30px;
    }

    .form-row{
      margin: -25px;

      .col{
        padding: 25px;
      }
    }
  }

  &__code{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px -15px -15px;
    font-weight: 500;
    &-item{
      margin: 15px;
    }
    &-link{
      margin: 15px;
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid;
      opacity: .4;

      &:hover{
        opacity: 1;
        color: inherit;
      }
    }
  }

  @media (max-width: 991px) {
    &__content{
      border-radius: 0;
      padding: 60px 0;

      form{
        max-width: 100%;
      }

      &-icon{
        width: 100px;
        height: 100px;
      }

      &-title{
        font-size: 30px;
      }
      .form-row{
        margin: -10px;

        .col{
          padding: 10px;
        }
      }
    }
  }
}

.wrapper.wrapper-404{
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  @media (max-width: 991px) {
    padding: 0;
  }
  .alert{
    font-size: 15px;
  }
  .alert-danger{
    color: #fff;
    background: $pink;
    border-color: $pink;
  }
  .alert-success{
    color: #fff;
    background: $teal;
    border-color: $teal;
  }
}
.wrapper-404{
  &__content{
    flex: 1 0 auto;
    background: $primary;
    border-radius: 30px;
    padding: 8vh 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__title{
    display: block;
    margin: 60px 0 38px;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
  }
  &__img{
    display: block;
    margin: 0 auto;
    max-width: 1340px;
  }
  &__svg{
    display: block;
    margin: 0 auto;
    width: 100%;
    fill: none;
    max-height: 42vh;

    &-plant-1{
      animation: mountains404 3s ease-in infinite;
    }
    &-plant-2{
      animation: mountains404 5s ease-in reverse infinite;
    }
    &-plant-3{
      animation: mountains404 3s ease-in reverse infinite;
    }
    &-plant-4{
      animation: mountains404 6s ease-in infinite;
    }
    &-plant-5{
      animation: mountains404 6s ease-in reverse infinite;
    }
    &-mountains{
      path{
        &:nth-child(1){
          animation: mountains404 10s ease-in infinite;
        }
        &:nth-child(2){
          animation: mountains404 10s ease-in reverse infinite;
        }
        &:nth-child(3){
          animation: mountains404 15s ease-in infinite;
        }
        &:nth-child(4){
          animation: mountains404 15s ease-in reverse infinite;
        }
      }
    }
    &-oops{
      opacity: .7;
      animation: oops404 1.5s ease-in infinite;
      *{
        opacity: .7;
        animation: oops404 1.5s ease-in infinite;
      }
    }
    &-not{
      opacity: .43;
      animation: not404 2s ease-in infinite;
      *{
        opacity: .43;
        animation: not404 2s ease-in infinite;
      }
    }
    &-4-1{
      transform-origin: center;
      animation: n4_404 15s ease-in infinite;
    }
    &-4-2{
      transform-origin: center;
      animation: n4_404 15s ease-in reverse infinite;
    }
    &-0{
      transform-origin: center;
      animation: n0_404 20s ease-in reverse infinite;
    }
  }
  @media (max-width: 991px) {
    &__content{
      border-radius: 0;
      padding: 60px 0;
    }
    &__title{
      font-size: 30px;
    }
  }
}

@keyframes oops404 {
  0%{
    opacity: .7;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: .7;
  }
}
@keyframes not404 {
  0%{
    opacity: .43;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: .43;
  }
}
@keyframes mountains404 {
  0%{
    transform: translateX(0%);
  }
  50%{
    transform: translateX(5%);
  }
  100%{
    transform: translateX(0%);
  }
}
@keyframes plant404 {
  0%{
    transform: translateX(0%);
  }
  50%{
    transform: translateX(5%);
  }
  100%{
    transform: translateX(0%);
  }
}
@keyframes n4_404 {
  0%{
    transform: rotate(0deg) scale(1);
  }
  50%{
    transform: rotate(1deg) scale(.9);
  }
  100%{
    transform: rotate(0deg) scale(1);
  }
}
@keyframes n0_404 {
  0%{
    transform: rotate(0deg) scale(1);
  }
  50%{
    transform: rotate(-2deg) scale(.9);
  }
  100%{
    transform: rotate(0deg) scale(1);
  }
}

.wrapper.wrapper-505{
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  @media (max-width: 991px) {
    padding: 0;
  }
  .alert{
    font-size: 15px;
  }
  .alert-danger{
    color: #fff;
    background: $pink;
    border-color: $pink;
  }
  .alert-success{
    color: #fff;
    background: $teal;
    border-color: $teal;
  }
}
.wrapper-505{
  &__content{
    flex: 1 0 auto;
    background: $primary;
    border-radius: 30px;
    padding: 8vh 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__title{
    display: block;
    margin: 0 0 35px;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
  }
  &__img{
    display: block;
    margin: 0 auto;
    max-width: 1340px;
  }
  &__svg{
    display: block;
    margin: 0 auto;
    width: 100%;
    fill: none;
    max-height: 49.5vh;
    &-plant-1{
      animation: mountains404 3s ease-in infinite;
    }
    &-plant-2{
      animation: mountains404 5s ease-in reverse infinite;
    }
    &-plant-3{
      animation: mountains404 3s ease-in reverse infinite;
    }
    &-clouds{
      animation: mountains404 10s ease-in reverse infinite;
    }
    &-mountains{
      path{
        &:nth-child(1){
          animation: mountains404 10s ease-in infinite;
        }
        &:nth-child(2){
          animation: mountains404 10s ease-in reverse infinite;
        }
        &:nth-child(3){
          animation: mountains404 15s ease-in infinite;
        }
        &:nth-child(4){
          animation: mountains404 15s ease-in reverse infinite;
        }
        &:nth-child(5){
          animation: mountains404 10s ease-in infinite;
        }
      }
    }
    &-0-1{
      transform-origin: center;
      animation: n4_404 15s ease-in infinite;
    }
    &-0-2{
      transform-origin: center;
      animation: n4_404 15s ease-in reverse infinite;
    }
    &-5{
      transform-origin: center;
      animation: n0_404 20s ease-in reverse infinite;
    }
  }
  @media (max-width: 991px) {
    &__content{
      border-radius: 0;
      padding: 60px 0;
    }
    &__title{
      font-size: 30px;
    }
  }
}

.wrapper.wrapper-error{
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  @media (max-width: 991px) {
    padding: 0;
  }
  .alert{
    font-size: 15px;
  }
  .alert-danger{
    color: #fff;
    background: $pink;
    border-color: $pink;
  }
  .alert-success{
    color: #fff;
    background: $teal;
    border-color: $teal;
  }
}
.wrapper-error{
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content{
    flex: 1 0 auto;
    background: $primary;
    border-radius: 30px;
    padding: 8vh 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__title{
    display: block;
    margin: 20px 0 38px;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
  }
  &__txt{
    text-align: center;
    white-space: nowrap;
    flex-grow: 1;
    &-title{
      display: block;
      font-size: 250px;
      line-height: 1;
      font-weight: 800;
      font-family: $headings-font-family;
    }
  }
  &__img{
    padding-right: 50px;
    flex-grow: 1;
    height: 73vh;
  }
  &__svg{
    display: block;
    margin: 0 auto;
    fill: none;
    width: 100%;
    height: 100%;

    &-leg-1{
      animation: leg1_Error 1s ease-in infinite;
    }
    &-leg-2{
      animation: tail_Error 1s ease-in infinite;
    }
    &-tail{
    }

    &-booty{
      animation: bootyError 2s ease-in infinite;
      transform-origin: 34% 34%;
    }
    &-belly{
      animation: bellyError 1s ease-in infinite;
    }
    &-ball{
      animation: ballError 2s ease-in infinite;
    }
    &-bubble{
      transform-origin: 60% 60%;
      animation: bubbleError 2s ease-in infinite;
    }
  }
  @media (max-width: 1499px) {
    &__title{
      font-size: 40px;
    }
    &__txt{
      &-title{
        font-size: 200px;
      }
    }
  }
  @media (max-width: 991px) {
    &__content{
      border-radius: 0;
      padding: 60px 0;
    }
    &__title{
      font-size: 30px;
    }
    &__txt{
      &-title{
        font-size: 140px;
      }
    }
  }
  @media (max-width: 767px) {
    .container{
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__img{
      padding-right: 0;
      flex-grow: unset;
      height: 32vh;
      margin-bottom: 5vh;
    }
    &__title{
      white-space: normal;
    }
    &__txt{
      &-title{
        font-size: 100px;
      }
    }
  }
}

@keyframes leg1_Error {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(-1deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
@keyframes tail_Error {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(1deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
@keyframes bootyError {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(.7);
  }
  100%{
    transform: scale(1);
  }
}
@keyframes bellyError {
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(1%);
  }
  100%{
    transform: translateY(0);
  }
}
@keyframes ballError {
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateX(-3%);
  }
  100%{
    transform: translateY(0);
  }
}
@keyframes bubbleError {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(.9);
  }
  100%{
    transform: scale(1);
  }
}

.jumbotron{
  background: $bg-light;
  border-radius: $block-radius;
  position: relative;
  overflow: hidden;
  padding: 4.3em;
  margin: 0;

  &__img{
    top: -50%;
    left: 50%;
    right: 0;
    bottom: -50%;
    position: absolute;
    display: block;
    color: #1B1B1B;
    pointer-events: none;

    .icon{
      display: block;
      width: 100%;
      height: 100%;
      animation: wrapperLogin 12s linear infinite;
      will-change: transform;
    }
  }
  &__title{
    display: block;
    margin-bottom: 40px;
    font-weight: 800;
    font-size: 48px;
    line-height: 1.2;
    font-family: $headings-font-family;

    b{
      display: block;
      color: $color-light;
      font-weight: 800;
    }
  }
  &__txt{
    .form-control__field{
      max-width: 382px;
    }
    .link-doc{
      display: none;
    }
    .jumbotron__btn{
      display: none;
    }
  }

  &.jumbotron--title{
    border-radius: 0;
    background: none;
    border-top: 1px solid $bg-light;
    border-bottom: 1px solid $bg-light;
    .jumbotron__title{
      margin-bottom: 0;
      text-align: center;
    }
  }

  @media (max-width: 991px) {
    background: url(../img/bg/bg-mobile.svg) no-repeat center #4772D6;
    background-size: cover;
    margin-left: -30px;
    margin-right: -30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 120px 30px 60px;

    &.jumbotron--title{
      background: url(../img/bg/bg-mobile.svg) no-repeat center #4772D6;
      background-size: cover;
      margin-left: -30px;
      margin-right: -30px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 120px 30px 60px;
      border-top: 0;
      border-bottom: 0;
      .jumbotron__title{
        margin-bottom: 30px;
      }
    }

    &__img{
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .icon{
        display: none;
      }
    }
    &__title{
      margin-bottom: 30px;
      font-size: 40px;
      text-align: center;

      b{
        color: rgba(#fff,.5);
      }
    }
    &__txt{
      .form-control__field{
        max-width: 100%;
      }
      .link-doc{
        display: block;
        text-align: center;
        height: 50px;
        line-height: 50px;
        background: #4772D6;
        border-radius: 5px;
        color: rgba(#fff,.5);
        .link-doc__title,.icon{
          color: #fff;
        }
      }
      .jumbotron__btn{
        display: block;
        width: 100%;
        margin-top: 25px;
      }
    }
  }
  @media (max-width: 413px) {
    margin-left: -15px;
    margin-right: -15px;
    padding: 100px 15px 60px;
    &__txt{
      .link-doc{
        .icon{
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  @media (max-width: 374px) {
    &__title{
      font-size: 30px;
    }
  }
}

@media (max-width: 991px){
  .home-planet{
    display: none;
  }
}

.main-controls{
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  margin: -25px;
  font-size: 15px;
  &__col{
    padding: 25px;

    &:not(:first-child){
      flex-grow: 1;
    }
  }
  &__nav{
    border-radius: 10px;
    background: $bg-light;
    padding: 0 26px;

    ul{
      list-style: none;
      padding: 0;
      margin: 0 -20px;
      line-height: .95;
      display: flex;
      justify-content: space-between;

      li{
        margin: 0 20px;
        padding: 22px 0;
        position: relative;

        &:before{
          @include pseudo();
          height: 4px;
          left: 0;
          right: 0;
          bottom: 0;
          background: $primary;
          opacity: 0;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &.active{
          a{
            color: $color-base;
          }
          &:before{
            opacity: 1;
          }
        }
      }

      a{
        color: $color-light;
        display: inline-block;
        vertical-align: top;

        &:hover{
          color: $color-base;
        }
      }
    }
  }
  &__info{
    border-radius: 10px;
    background: $bg-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &-col{
      padding: 0 26px;
      display: flex;
      min-height: 38px;
      align-items: center;


      &.main-controls__info-trust{
        .badge-trust-circle{
          display: none;
        }
      }

      &:first-child{
        flex-grow: 1;
        padding-right: 6px;
      }

      &:last-child{
        border-left: 1px solid $input-border;
      }
    }
    &-title{
      font-size: 17px;
    }
    .badge-trust{
      margin-left: 32px;
    }
    &-item{
      font-size: 13px;
      color: $color-light;
      &-number{
        color: $color-base;

        &-link{
          display: none;
        }
      }
    }
  }

  body.day-mode &{
    &__nav{
      background: $bg-day-light;

      ul{
        li{
          &.active{
            a{
              color: $color-day-base;
            }
          }
        }

        a{
          color: $color-day-lighter;

          &:hover{
            color: $color-day-base;
          }
        }
      }
    }
    &__info{
      background: $bg-day-light;

      &-col{
        &:last-child{
          border-color: $input-day-border;
        }
      }

      &-item{
        font-size: 13px;
        color: $color-day-lighter;
        &-number{
          color: $color-day-base;
        }
      }
    }
  }

  @media (max-width: 1720px){
    flex-wrap: wrap;
  }
  @media (max-width: 991px){
    &__col{
      width: 100%;
      &:first-child{
        display: none;
      }
    }
    &__nav{
      margin: 0 -30px;
      border-radius: 0;
      padding: 0 30px;

      ul{
        margin: 0 -5px;

        li{
          font-size: 13px;
          margin: 0 5px;
          padding: 23px 0;
        }
      }
    }
    &__info{
      padding: 26px 26px 26px 126px;
      min-height: 152px;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
      }
      &-col{
        padding: 8px 0 8px 20px;
        min-height: unset;
        display: block;
        width: 100%;
        border: 0 !important;

        &.main-controls__info-trust{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
          padding: 0;
          border: 0;
          position: absolute;
          flex-grow: unset;
          top: 50%;
          margin-top: -50px;
          left: 26px;

          .main-controls__info-title{
            font-size: 15px;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 10px;
          }

          .badge-trust-circle{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
          }

          .badge-trust-number{
            margin: 0;
            font-size: 10px;
            height: 17px;
            padding: 4px 8px;
          }

          .badge-trust-line{
            display: none !important;
          }
        }

      }
      &-title{
        font-size: 17px;
      }
      .badge-trust{
        margin-left: 32px;
      }
      &-item{
        &-number{
          display: block;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
          flex-grow: 1;
          padding-left: 10px;
          overflow: hidden;
          text-align: right;
          &.main-controls__info-item-number-desktop{
            display: none;
          }

          &-link{
            display: block;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
            flex-grow: 1;
            padding-left: 10px;
            overflow: hidden;
            text-align: right;
          }
        }
      }
    }
  }
  @media (max-width: 413px){
    &__nav{
      margin: 0 -15px;
      padding: 0 15px;
    }
  }
  @media (max-width: 374px){
    &__nav{
      li{
        font-size: 12px;
      }
    }
  }
}

.main-item{
  background: $bg-light;
  border-radius: $block-radius;
  position: relative;

  & + .main-item{
    margin-top: 50px;
  }

  &__head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 32px;

    &-controls{
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn-primary{
        margin: -11px 0;
      }

      &.main-item__head-controls-hidden{
        display: none;
      }
    }

    &-title{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon{
        width: 24px;
        height: 24px;
        fill: $primary;
      }

      &-txt{
        display: block;
        padding-left: 20px;
        font-size: 18px;
      }

      &-icon{
        width: 79px;
        height: 79px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 22px;
        border-radius: 50%;
        border: 1px solid $input-border;
        background: $input-border;
        overflow: hidden;
        transition: $transition;

        .icon{
          width: 100%;
          height: 100%;
        }

        &.main-item__head-title-profile{
          background-image: url(../img/icons/profile-icon.svg);
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 60%;
        }
      }

      &-bill{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-item{
          font-size: 26px;
          font-weight: 500;
          margin-right: 30px;
        }
        .link-icon{
          margin-left: 30px;
        }

        &-content{
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-controls{
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .form-control__field{
            width: 280px;
            .btn{
              margin-left: 0;
              color: $color-base;

              body.day-mode &{
                color: $color-day-base;
              }
            }
          }
          .btn{
            margin-left: 20px;
          }
        }
      }
    }

    & + .main-item__content{
      border-top: 1px solid $input-border;
    }

    &.main-item__head-xl{
      padding: 41px 45px;

      .main-item__head-title-txt{
        width: calc(100% - 79px);
        padding-left: 30px;

        span{
          font-size: 20px;
          color: $color-base;
          display: block;
          line-height: 1;
          margin-bottom: 15px;
        }
        p{
          color: $color-light;
          font-size: 14px;
          margin: 0;
        }
        a{
          color: $primary;
          border-bottom: 1px solid transparent;

          &:hover{
            color: $primary;
            border-color: rgba($primary,.4);
          }
        }
      }
    }
  }
  &__content{
    &.main-item__content-xl{
      border-top: 0;

      .main-item__form{
        padding: 0 45px 45px;
      }
    }
  }
  &__table{
    overflow: auto;
    scrollbar-color: $primary $input-border;
    scrollbar-width: thin;
    scrollbar-height: thin;
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: $input-border;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: $primary;
    }

    &-pull{
      display: none;
    }

    .path-item,.database-item{
      position: relative;
      max-width: 206px;
      background: -webkit-linear-gradient(left, #999B9F,#999B9F 60%, rgba(211, 212, 231, 0) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      body.day-mode &{
        background: -webkit-linear-gradient(left, #656d82,#656d82 60%, rgba(211, 212, 231, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .database-item{
      max-width: 375px;
    }
    .badge-in,.badge-out,.badge-outline{
      margin-top: -1px;
      margin-bottom: -1px;
    }

    &.main-item__table--bill{
      padding: 0 45px;
      .table{
        font-size: 16px;
        td{
          height: 58px;

          &:first-child{
            color: $color-light;
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
      body.day-mode &{
        .table{
          td{
            &:first-child{
              color: $color-day-lighter;
            }
          }
        }
      }
    }

    &.main-item__table--mobile{
      @media (max-width: 991px){
        overflow: visible;
        background: $bg-base;

        tr{
          background: $bg-light;
        }
      }
    }
    &.main-item__table-tickets{
      width: calc(100% + 10px);
      margin-left: -10px;
      padding-left: 10px;

      @media (max-width: 991px){
        overflow: visible;
        background: $bg-base;
        margin-left: 0;
        padding-left: 0;
        width: 100%;

        tr{
          background: $bg-light;
        }
      }

      body.day-mode &{
        background: none;
      }
    }
  }

  &.main-item--profile{
    background-image: url(../img/bg/profile-decor.png);
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  &__form{
    font-size: 14px;
    .custom-control{
      font-size: 14px;
      &-label {
        padding-top: 2px;
      }
    }
    .row{
      margin: -15px;
      align-items: flex-end;
    }
    .col{
      padding: 15px;

      &.d-flex{
        margin-top: 15px;
      }
    }
  }
  body.day-mode &{
    background: none;

    &__table{
      background: $bg-day-light;
      border-bottom-left-radius: $block-radius;
      border-bottom-right-radius: $block-radius;
      scrollbar-color: #E9E9EE $primary;
      &::-webkit-scrollbar-track {
        background-color: #E9E9EE;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
      }

      &.main-item__table--mobile{
        @media (max-width: 991px){
          background: none;

          tr{
            background: $bg-day-light;
          }
        }
      }
    }
    &__head{
      color: $color-day-base;
      background: $bg-day-light;
      border-top-left-radius: $block-radius;
      border-top-right-radius: $block-radius;

      &-title{
        &-icon{
          border-color: $input-day-border;
          background: $input-day-border;

          &.main-item__head-title-profile{
            background-image: url(../img/icons/profile-icon-day.svg);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 60%;
          }
        }
      }

      & + .main-item__content{
        border-color: $input-day-border;
      }

      &.main-item__head-xl{
        .main-item__head-title-txt{
          span{
            color: $color-day-base;
          }
          p{
            color: $color-day-lighter;
          }
        }
      }
    }
    &.main-item--profile{
      background-image: url(../img/bg/profile-decor-day.png);
    }
  }

  @media (max-width: 991px){
    &__head{
      &-controls{
        .link-base{
          font-size: 0;
          color: transparent;

          .icon{
            color: $primary;
            width: 14px;
            height: 14px;
          }
        }
        &.main-item__head-controls-hidden{
          display: flex;
        }
      }
    }
  }
  @media (max-width: 991px){
    &__head{
      &-controls{
        .btn-primary{
          font-size: 0;
          padding: 13px 13px;
          margin-right: -11px;

          .icon{
            font-size: 22px;
            margin: 0;
          }
        }
      }
      &.main-item__head-xl{
        padding: 40px 30px;

        .main-item__head-title-txt{
          padding-left: 30px;

          span{
            font-size: 20px;
          }
          p{
            font-size: 14px;
          }
        }
      }
      &-title{
        &-bill{
          flex-wrap: wrap;
          &-content{
            flex-wrap: wrap;
          }

          &-item{
            width: 100%;
            margin-bottom: 10px;
          }
          &-controls{
            margin-top: 10px;
            width: 100%;

            .btn{
              margin-left: 0;
              margin-right: 10px;
              width: calc(50% - 10px);
            }
          }
        }
      }
    }

    &__table{
      &.main-item__table--bill{
        padding: 0 26px;
      }
    }
    &__content{
      &.main-item__content-xl{
        .main-item__form{
          padding: 0 30px 45px;
        }
      }
    }
  }
  @media (max-width: 413px){
    &__head {
      padding-left: 25px;
      padding-right: 25px;
    }
    &__head{
      &.main-item__head-xl{
        padding: 35px 15px;

        .main-item__head-title-txt{
          padding-left: 20px;

          span{
            font-size: 16px;
            margin-bottom: 10px;
          }
          p{
            font-size: 12px;
          }
        }
      }
    }
    &__content{
      &.main-item__content-xl{
        .main-item__form{
          padding: 0 15px 35px;
        }
      }
    }
  }
  @media (max-width: 374px){
    &__head{
      padding-left: 15px;
      padding-right: 15px;
      &-title{
        &-icon{
          width: 50px;
          height: 50px;
          padding: 10px;
        }

      }
      &.main-item__head-xl{
        .main-item__head-title-txt{
          width: calc(100% - 50px);
          padding-left: 15px;

          span{
            font-size: 16px;
            margin-bottom: 10px;
          }
          p{
            font-size: 10px;
          }
        }
      }
    }
  }
}

.main-bill{
  display: flex;

  &__content{
    width: calc(72% - 20px);
  }
  &__aside{
    width: 28%;
    background: $primary;
    margin-left: 20px;
    text-align: center;
    padding: 60px 50px 60px;
    color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img{
      display: block;
      margin: 0 auto 50px;
      width: 100%;
    }
    &-title{
      display: block;
      font-size: 26px;
      margin-bottom: 30px;
      line-height: 1.38;
      font-weight: 500;

      a{
        color: inherit;
        border-bottom: 1px solid;

        &:hover{
          border-color: transparent;
        }
      }
    }
    .btn{
      margin-top: auto;
    }
    &-price{
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      color: rgba(#fff,.5);
      b{
        font-size: 30px;
        color: #fff;
        font-weight: 500;
        margin-right: 4px;
      }
    }
  }
  @media (max-width: 1620px){
    &__aside{
      padding: 60px 30px 60px;
      &-title{
        font-size: 22px;
      }
    }
  }
  @media (max-width: 1790px){
    display: block;
    &__content{
      width: 100%;
    }
    &__aside{
      display: none;
    }
  }

  @media (max-width: 991px){
    .main-item.main-item-with-controls{
      padding-bottom: 186px;
      position: relative;

      body.day-mode &{
        background: $bg-day-light;

        .main-item__table,.main-item__head{
          background: none;
        }
      }
    }
    .main-item__head.main-item__head-xl {
      padding: 14px 32px;
      border-bottom: 1px solid $input-border;
      position: static;


      body.day-mode &{
        border-color: $input-day-border;
      }
    }
    .main-item__table {
      overflow: visible;
      padding: 0;
      white-space: normal;

      .table {
        font-size: 13px;

        td:first-child {
          padding-left: 32px;
          padding-right: 10px;
        }

        td:last-child {
          padding-left: 10px;
          padding-right: 32px;
        }
      }
    }

  }
  @media (max-width: 413px){
    .main-item__head.main-item__head-xl {
      padding: 14px 25px;
    }
    .main-item__table {

      .table {

        td:first-child {
          padding-left: 25px;
          padding-right: 10px;
        }

        td:last-child {
          padding-left: 10px;
          padding-right: 25px;
        }
      }
    }

  }
  @media (max-width: 374px){
    .main-item__head.main-item__head-xl {
      padding: 14px 15px;
    }
    .main-item__table {

      .table {
        td:first-child {
          padding-left: 15px;
          padding-right: 10px;
        }

        td:last-child {
          padding-left: 10px;
          padding-right: 15px;
        }
      }
    }

  }
}

.main-item__head-title-bill{
  .btn{
    min-width: 118px;
  }
  @media (max-width: 1400px){
    &-item{
      font-size: 18px;
    }
    &-controls{
      .form-control__field {
        width: 240px;

        .btn{
          font-size: 12px;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 991px){
    position: static;
    &-content{
      width: 100%;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      .link-print{
        display: none;
      }
    }
    &-item{
      margin: 0;
      font-weight: 400;
      font-size: 18px;
    }
    &-controls{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 32px 20px;
      flex-wrap: wrap;

      .btn{
        margin-left: 0;
        margin-right: 16px;
        width: calc(50% - 16px);

        & + .btn{
          margin-left: 16px;
          margin-right: 0;
        }
      }

      .form-control__field {
        width: calc(100% + 64px);
        margin: 0 -32px 20px;
        padding: 20px 32px;
        border-bottom: 1px solid  $input-border;
        border-top: 1px solid  $input-border;

        body.day-mode &{
          border-color: $input-day-border;
        }

        .btn{
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 413px){
    &-controls{
      padding: 0 25px 20px;

      .btn{
        margin-left: 0;
        margin-right: 12px;
        width: calc(50% - 12px);

        & + .btn{
          margin-left: 12px;
          margin-right: 0;
        }
      }

      .form-control__field {
        width: calc(100% + 50px);
        margin: 0 -25px 20px;
        padding: 20px 25px;
      }
    }
  }
  @media (max-width: 374px){
    &-controls{
      padding: 0 15px 20px;

      .btn{
        margin-left: 0;
        margin-right: 8px;
        width: calc(50% - 8px);

        & + .btn{
          margin-left: 8px;
          margin-right: 0;
        }
      }

      .form-control__field {
        width: calc(100% + 30px);
        margin: 0 -15px 20px;
        padding: 20px 15px;
      }
    }
  }
}

.header{
  box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.2);
  position: absolute;
  z-index: 991;
  background: $bg-light;
  padding: 19px 0;
  top: 0;
  left: 0;
  width: 100%;

  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-logo{
    color: inherit;
    font-family: 'Proxima Nova Cn', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    span{
      color: $primary;
    }

    .icon{
      color: $primary;
      width: 36px;
      height: 36px;
      margin-right: 7px;
      vertical-align: bottom;
    }

    &:hover{
      color: $primary;
    }
  }

  &-nav{
    ul{
      @include listReset;
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        font-size: 13px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $color-light;
        line-height: 1;
        padding: 7px 0;
        border-bottom: 1px solid transparent;
        &:hover{
          color: $color-base;
        }
      }

      li{
        margin: 0 20px;
        &.active{
          a{
            color: $color-base;
            border-color: $primary;
          }
        }
      }
    }

  }
  &-pull,&-profile{
    display: none;
  }
  &-controls{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .link{
      color: $color-base;
      font-weight: 500;
      text-decoration: none;
      margin-left: 32px;

      &:hover{
        color: $color-light;
      }
    }
  }

  &-lang{
    list-style: none;
    padding: 0;
    margin: 0 89px 0 0;
    display: flex;
    flex-direction: column;
    height: 31px;
    width: 70px;
    position: relative;

    &__item{
      order: 1;
      color: $color-light;
      width: 100%;
      height: 31px;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: $transition-base;
      background: #353535;

      a{
        display: block;
        width: 100%;
        height: 31px;
        line-height: 31px;
        position: relative;
        color: inherit;
        text-transform: uppercase;
        font-size: 13px;
        text-align: center;
        letter-spacing: 2px;

        &:after{
          @include pseudo();
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          right: 0;
          top: 50%;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
          opacity: 0;
          pointer-events: none;
        }

        &:hover{
          color: $color-base;

          &:after{
            transform: rotate(-135deg);
            border-color: $color-base;
            margin-top: 0;
          }
        }
      }

      &.active{
        order: 0;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        color: $color-light;
        background: none;

        a{
          color: inherit;
          &:after{
            opacity: 1;
          }
        }
      }
    }

    &:hover{
      .header-lang__item{
        opacity: 1;
        visibility: visible;
        pointer-events: auto;

        &.active{
          a:after{
            transform: rotate(-135deg);
            border-color: $color-base;
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (max-width: 1499px) {
    &-nav{
      ul{
        li{
          margin: 0 15px;
        }
      }

    }
    &-lang{
      margin: 0 32px 0 0;
    }
  }
  @media (max-width: 1279px) {
    &-pull{
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0;
      width: 50px;
      height: 50px;
      position: relative;
      background: $primary;
      border-radius: 5px;
      margin-right: 30px;
      border: 0;
      
      &:focus{
        outline: none;
      }
      
      .icon{
        width: 27px;
        height: 27px;
      }
    }
    &-logo{
      margin-right: auto;
    }
    &-nav{
      display: none;
    }
  }
  @media (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
    &-logo{
      display: none;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: none;
    background: none;
    &-pull{
      color: $primary;
      height: 40px;
      background: #fff;
    }
    &-controls{
      display: none;
    }
    &-profile{
      display: flex;
      width: 40px;
      color: $primary;
      height: 40px;
      background: #fff;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      .icon{
        width: 28px;
        height: 28px;
      }

      &:hover{
        color: $primary;
      }
    }
  }
  @media (max-width: 413px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.header-admin{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 991;
  padding: 19px 0;

  .header-admin__profile{
    cursor: pointer;

    &:hover{
      &:before{
        transform: rotate(-135deg);
        margin-top: -1px;
      }
      .header-admin__profile-hidden{
        @include show;
      }
    }
  }

  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    min-height: 51px;
  }

  &-logo{
    color: inherit;
    font-family: 'Proxima Nova Cn', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 132px;
    letter-spacing: 0.05em;

    .icon{
      color: $primary;
      width: 28px;
      height: 28px;
      margin-right: 7px;
    }

    &:hover{
      color: $primary;
    }
  }

  &-nav{
    flex-grow: 1;
    margin: 0 -24px;
    ul{
      @include listReset;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a{
        font-size: 14px;
        color: $color-light;
        line-height: 1;
        padding: 7px 0;
        border-bottom: 1px solid transparent;
        &:hover{
          color: $color-base;
        }

        .icon{
          width: 20px;
          height: 20px;
          margin-right: 7px;
        }
      }

      li{
        margin: 0 24px;
        &.active{
          a{
            color: $color-base;
            border-color: $primary;
          }
        }
      }
    }

  }
  &-controls{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__notifications{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bg-light;
    border: 1px solid $input-border;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    color: $color-light;
    transition: $transition-base;
    cursor: pointer;

    &-block{
      position: relative;
    }

    &:hover{
      color: $warning;
    }
    .icon{
      width: 16px;
      height: 16px;
    }
    &-number{
      position: absolute;
      width: 18px;
      height: 18px;
      top: -3px;
      right: -5px;
      background: #FF754C;
      color: #fff;
      text-align: center;
      line-height: 18px;
      border-radius: 50%;
      font-size: 9px;
    }

    &-dropdown{
      background: #353535;
      border-radius: 10px;
      color: $color-base;
      padding: 0 25px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 400px;
      z-index: 99;
      margin-top: 16px;
      display: none;

      &-close{
        display: none;
      }

      &:before{
        content: '';
        border: 6px solid #353535;
        position: absolute;
        bottom: 100%;
        border-top-color: transparent;
        border-left-color: transparent;
        transform: rotate(-135deg);
        margin-bottom: -7px;
        left: 50%;
        margin-left: -6px;
      }
    }
    &-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0 20px;

      &-title{
        display: block;
        font-size: 17px;
        line-height: 1;
        font-weight: 500;
      }
      &-link{
        font-size: 13px;
        line-height: 1;
      }
    }
    &-list{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    &-item{
      border-top: 1px solid #3D3D3D;
      padding: 20px 0;

      a{
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #2C2C2C;
        color: $color-light;
        width: 40px;
        height: 40px;
        padding: 10px;

        .icon{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 40px);
        padding-left: 15px;
        font-size: 15px;
      }
      &-time{
        white-space: nowrap;
        font-size: 11px;
        color: $color-light;
        padding-left: 10px;

      }
    }
  }
  &__profile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-left: 74px;
    padding-right: 25px;
    color: $color-light;

    &-hidden{
      position: absolute;
      @include hide;
      top: 100%;
      background: #2C2C2C;
      border-radius: 3px;
      padding: 18px 23px;
      right: 0;
      transition: $transition-base;

      a{
        color: $color-base;
        display: block;
        white-space: nowrap;
        font-size: 14px;
        line-height: 1;

        .icon{
          color: $primary;
          width: 10px;
          height: 10px;
          margin-right: 10px;
          vertical-align: middle;
        }

        &:hover{
          color: $primary;
        }
      }
    }

    &:before{
      @include pseudo();
      border: 2px solid;
      width: 6px;
      height: 6px;
      transform: rotate(45deg);
      border-top: 0;
      border-left: 0;
      top: 50%;
      margin-top: -5px;
      right: 1px;
      transition: $transition-base;
    }

    &-avatar{
      display: flex;
      align-items: center;
      justify-content: center;
      background: $bg-light;
      border: 1px solid $input-border;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      color: $bg-base;

      .icon{
        width: 28px;
        height: 28px;
      }
    }

    &-title{
      width: calc(100% - 40px);
      padding-left: 20px;
      font-size: 14px;
      position: relative;
      white-space: nowrap;

      b{
        color: $color-base;
        font-weight: 400;
      }
    }
  }
  &__mode{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    position: relative;

    &-decor{
      background: $bg-light;
      border: 1px solid $input-border;
      margin: 0 20px;
      height: 40px;
      width: 74px;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      pointer-events: none;

      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $transition-base;
      }
      &-night{
        transform: translateX(100%);
        &.active{
          transform: none;
        }
      }
      &-day{
        transform: translateX(-100%);
        &.active{
          transform: none;
        }
      }
    }
    &-item{
      color: $color-light;
      font-size: 14px;

      &:before{
        @include pseudo();
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover{
        color: $color-base;
      }

      &.active{
        color: $color-base;
        font-weight: 500;
        pointer-events: none;
      }
    }
  }
  &-pull{
    display: none;
  }
  @media (max-width: 1420px){
    &__profile{
      &-title{
        display: none;
      }
    }
  }
  @media (max-width: 1279px){
    padding: 30px 0;
    .container{
      padding-left: 30px;
      padding-right: 30px;
    }
    &-pull{
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-light;
      padding: 0;
      width: 50px;
      height: 40px;
      position: relative;
      background: $bg-light;
      border-radius: 5px;
      margin-right: 30px;
      border: 0;

      &:focus{
        outline: none;
      }

      .icon{
        width: 27px;
        height: 27px;
      }
    }
    &-nav{
      display: none;
    }
    &-logo{
      display: none;
    }
    &__mode{
      display: none;
    }
    &__profile{
      margin-left: 39px;
    }

    &__notifications{
      &-dropdown{
        padding-top: 30px;
        border-radius: 20px;
        top: 0;
        position: fixed;
        margin: 0;
        z-index: 9999;
        width: 100%;
        left: 0;
        transform: none;
        bottom: 0;
        overflow: auto;
        background: linear-gradient(180deg, #303030 -22.74%, #222222 62.36%);
        &:before{
          border: 0;
          background: #303030;
          margin: 0;
          top: -13px;
          left: 14px;
          right: 14px;
          transform: none;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          height: 13px;
          display: none;
        }
        &-close{
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 25px;
          background: #303030;
          color: $primary;
          padding: 14px;
          border-radius: 50%;

          .icon{
            display: block;
            width: 100%;
            height: 100%;
          }

          &:focus{
            outline: none;
          }
        }
      }
    }
  }

  @media (max-width: 413px){
    padding: 15px 0;
    .container{
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  body.day-mode &{
    &-logo{
      color: $color-day-base;
      &:hover{
        color: $primary;
      }
    }

    &-nav{
      ul{
        a{
          color: $color-day-lighter;
          &:hover{
            color: $primary;
          }
        }

        li{
          &.active{
            a{
              color: $primary;
              border-color: $primary;
            }
          }
        }
      }

    }

    &__notifications{
      background: #DEE0EA;
      mix-blend-mode: color-burn;
      border-color: #C1C2D1;
      color: $color-day-lighter;

      &-dropdown{
        background: #DEE0EA;
        mix-blend-mode: color-burn;
        color: $color-day-base;

        &:before{
          border-color: #DEE0EA;
          border-top-color: transparent;
          border-left-color: transparent;
          mix-blend-mode: color-burn;
        }
      }
      &-item{
        border-top: #C1C2D1;

        &-icon{
          background: rgba(#fff,.3);
          color: $color-day-lighter;
        }
        &-time{
          color: $color-day-lighter;

        }
      }
    }
    &__profile{
      &-avatar{
        background: #DEE0EA;
        mix-blend-mode: color-burn;
        border-color: #C1C2D1;
        color: $color-day-lighter;
      }

      &-title{
        color: $color-day-lighter;
        b{
          color: $color-day-base;
        }
      }
    }
    &__mode{
      &-decor{
        background: #DEE0EA;
        mix-blend-mode: color-burn;
        border-color: #C1C2D1;
      }
      &-item{
        color: $color-day-lighter;
        &:hover{
          color: $color-day-base;
        }

        &.active{
          color: $color-day-base;
        }
      }
    }

    @media (max-width: 1279px){
      &-pull{
        color: $color-day-lighter;
        background: rgba(#fff,.3);
        backdrop-filter: blur(30px);
      }
      &__notifications{
        &-dropdown{
          mix-blend-mode: unset;
          &-close{
            background: rgba(#fff,.3);
            color: $primary;
          }
        }
      }
    }
  }
}

.footer{
  background: $bg-light;
  padding-top: 39px;

  &-main{
    padding: 15px 0;
    color: $color-light;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 -15px;

    &__item{
      padding: 15px;
      font-size: 14px;

      ul{
        @include listReset;

        li + li{
          margin-top: 15px;
        }

        a{
          color: inherit;
          display: inline-block;
          vertical-align: top;

          &:hover{
            color: $primary;
          }
        }
      }
    }
    &__title{
      display: block;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-base;
      margin-bottom: 27px;
    }
    &__subtitle{
      font-family: $headings-font-family;
      color: $color-base;
      line-height: 1;
      font-size: 24px;
      margin-bottom: 27px;
      display: block;
    }
  }
  &-info{
    padding: 30px 0;
    border-top: 1px solid $input-border;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-light;
    font-size: 14px;

    &__links{
      list-style: none;
      padding: 0;
      margin: 0 -15px;
      display: flex;
      align-items: center;
      justify-content: center;

      li{
        margin: 0 15px;
      }
      a{
        color: inherit;

        &:hover{
          color: $primary;
        }
      }
    }
    &__copyright{
      display: block;
    }
  }

  @media (max-width: 1279px){
    display: none;
  }
}

.sidebar-admin{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 211px;
  padding: 130px 35px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  &__link{
    margin: 30px -18px 0;
    background: $bg-light;
    border-radius: 30px;
    display: block;
    padding: 33px 33px 23px;
    color: $color-light;
    font-weight: 500;

    b{
      color: $color-base;
      font-weight: inherit;
      border-bottom: 2px solid $primary;
    }

    &:hover{
      color: $color-light;
    }

    .icon{
      width: 61px;
      height: 61px;
      display: block;
      margin-top: 21px;
      stroke: #fff;
      fill: none;
    }
  }

  &__nav{
    margin: 0 -35px;

    ul{
      @include listReset;

      li + li{
        margin-top: 12px;
      }
    }

    a{
      position: relative;
      display: block;
      color: $color-light;
      padding: 18px 35px;
      z-index: 1;

      .icon{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }

      &:before{
        @include pseudo();
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg, #2A2A2A 20.51%, #222222 105.45%);
        opacity: 0;
        transition: $transition-base;
        z-index: -1;
      }

      &:after{
        @include pseudo();
        height: 32px;
        width: 3px;
        top: 50%;
        left: 0;
        margin-top: -16px;
        background: $primary;
        opacity: 0;
        transition: $transition-base;
      }

      &:hover{
        color: $primary;
      }
    }

    li{
      &.active{
        a{
          color: $primary;
          &:before,&:after{
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: 1279px){
    display: none;
  }

  body.day-mode &{
    &__link{
      background: $bg-day-light;
      color: $color-day-lighter;

      b{
        color: $color-day-base;
      }

      &:hover{
        color: $color-day-base;
      }
    }

    &__nav{
      a{
        color: $color-day-lighter;

        &:before{
          background: linear-gradient(90deg, #C3D3F7 20.4%, rgba(211, 212, 223, 0) 104.98%);
        }

        &:hover{
          color: $primary;
        }
      }

      li{
        &.active{
          a{
            color: $primary;
          }
        }
      }
    }
  }
}

.mobile-nav{
  display: none;
  @media (max-width: 1279px){
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 290px;
    z-index: 99;
    background: inherit;
    transform: translateX(-100%);
    transition: $transition-base;
    overflow: auto;
    padding: 72px 30px 42px;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    scrollbar-height: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: transparent;
    }

    .header-admin__mode {
      display: flex;
      margin-right: 0;
      justify-content: flex-start;
    }
    &__group{
      padding: 15px 0 25px;
      border-top: 1px solid $bg-light;

      &-title{
        display: block;
        font-size: 13px;
        line-height: 1;
        margin-bottom: 25px;
        color: $color-light;
      }
      &-list{
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 2;
        font-size: 16px;
        font-weight: 500;

        li{
          margin: 0;
        }

        a{
          display: block;
          color: $color-base;
          position: relative;

          &:before{
            @include pseudo();
            width: 3px;
            height: 18px;
            left: -16px;
            background: $primary;
            top: 50%;
            margin-top: -9px;
            opacity: 0;
          }
        }
        li.active{
          a{
            color: $primary;
            &:before{
              opacity: 1;
            }
          }
        }
      }
    }

    &_buttons{
      display: flex;
      margin: 0 -10px 30px;

      .btn{
        flex-grow: 1;
        margin: 0 10px;
        font-size: 15px;
        padding: 0;
        height: 50px;
        line-height: 48px;
      }
    }

    &__close{
      display: inline-flex;
      align-items: center;
      margin-bottom: 42px;
      padding: 0;
      font-size: 14px;
      color: $color-light;

      &:focus{
        outline: none;
      }
      .icon{
        width: 15px;
        height: 15px;
        margin-right: 15px;
        display: block;
      }
    }

    &.active{
      transform: translateX(0);
    }
    .header-admin__notifications{
      margin-left: auto;
      width: 45px;
      height: 45px;

      .icon{
        width: 16px;
        height: 16px;
      }
    }
    .header-admin__profile{
      margin: 0;

      &:before{
        display: none;
      }

      &-avatar{
        width: 45px;
        height: 45px;

        .icon{
          width: 31px;
          height: 31px;
        }
      }

      &-title{
        display: block;
        padding-left: 14px;
        line-height: 13px;

        b{
          display: block;
          margin-bottom: 13px;
          font-size: 15px;
          font-weight: 500;
        }
        a{
          color: $primary;

          .icon{
            width: 10px;
            height: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 413px){
    padding-top: 56px;
  }

  body.day-mode & {
    @media (max-width: 1279px){
      &__group{
        border-color: #D3D4E2;

        &-title{
          color: #2C3049;
        }
        &-list{
          a{
            color: #656D82;
          }
          li.active{
            a{
              color: $primary;
            }
          }
        }
      }

      &__close{
        color: #656D82;
      }
    }
  }
}

.billing-controls{
  @include flex;
  margin: -10px;

  &__col{
    width: 37%;
    padding: 10px;

    &.billing-controls__col--narrow{
      width: 26%;
    }

    .form-row{
      margin: -5px;
    }
    .col{
      padding: 5px;
    }

    &.billing-controls__col--hidden{
      display: none;
    }
  }

  &__content{
    background: $bg-light;
    transition: $transition;
    padding: 26px;
    border-radius: 10px;
    height: 100%;
  }

  &__title{
    display: block;
    margin-bottom: 24px;
    line-height: 1;
  }
  &__balance{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    &-icon{
      width: 34%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../img/bg/bg-admin-logo.svg) no-repeat center $bg-base;
      -webkit-background-size: cover;
      background-size: cover;
      color: $primary;
      height: 64px;
      border-radius: 10px;
      margin-top: 12px;
      margin-bottom: 12px;
      position: relative;

      &:before{
        @include pseudo();
        top: -12px;
        bottom: -12px;
        left: 12px;
        right: 12px;
        z-index: -1;
        background: $input-border;
        border-radius: 9px;
      }

      .icon{
        display: block;
        width: 32px;
        height: 32px;
      }
    }
    &-txt{
      width: 66%;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }
    &-title{
      margin-bottom: 13px;
      display: block;
      font-size: 12px;
      letter-spacing: 0.1em;
      line-height: 1;
      text-transform: uppercase;
      color: $color-light;
      width: 100%;
      align-items: center;
    }
    &-sum{
      display: block;
      font-size: 30px;
      line-height: 1;
      font-weight: 700;

      span{
        font-size: .73em;
        display: inline-block;
        vertical-align: middle;
      }
    }
    &-btn{
      border-radius: 50%;
      color: #fff;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 21px;
      padding: 6px;

      .icon{
        display: block;
        width: 100%;
        height: 100%;
        transform: rotate(90deg);
      }

      &:hover{
        background: darken($primary,10%);
        color: #fff;
      }
    }
  }
  body.day-mode & {
    &__balance{
      &-icon{
        background: url(../img/bg/bg-admin-logo-day.svg) no-repeat center #E8E8ED;
        -webkit-background-size: cover;
        background-size: cover;
        box-shadow: 0px 0px 4px rgba(200, 200, 223, 0.5);

        &:before{
          background: rgba(129, 134, 164, 0.25);
        }
      }
      &-title{
        color: $color-day-lighter;
      }
    }
    &__numbers{
      color: $color-day-lighter;

      span{
        color: $color-day-base;
      }
    }
  }
  body.day-mode & {
    &__content{
      background: $bg-day-light;
    }
  }
  @media (max-width: 1720px){
    &__col{
      width: 100%;

      &.billing-controls__col--narrow{
        width: 100%;
      }
    }
  }
  @media (max-width: 991px) {
    &__col{
      .form-row{
        margin: -9px;
      }
      .col{
        padding: 9px;

        &.col--billing-desk{
          display: none;
        }
      }

      &.billing-controls__col--hidden{
        display: block;
        margin: 20px 0;
        .billing-controls__content{
          border-radius: 30px;
          margin: 0 -30px;
          padding: 60px 30px 30px;
        }
      }
    }
  }
  @media (max-width: 413px){
    &__col{
      &.billing-controls__col--hidden{
        .billing-controls__content{
          border-radius: 15px;
          margin: 0 -15px;
          padding: 70px 15px 30px;
        }
      }
    }
  }
  @media (max-width: 374px) {
    &__balance{
      &-title{
        font-size: 10px;
      }
      &-sum{
        font-size: 24px;
      }
    }
  }
}

.account-controls{
  @include flex;
  margin: -10px;

  hr{
    border-style: dashed;
    border-color: $input-border;
    margin-top: 26px;
    margin-bottom: 26px;
  }

  &__col{
    width: 23.7%;
    padding: 10px;

    &.account-controls__col--narrow{
      width: 14%;

      .account-controls__content{
        padding-top: 21px;
        padding-bottom: 21px;
      }
    }
    &.account-controls__col--wide{
      width: 62.3%;

      .account-controls__title{
        padding-bottom: 13px;
        margin-bottom: -17px;
        margin-top: 17px;
      }
    }
    &.account-controls__col-mobile{
      display: none;
    }
  }

  &__content{
    background: $bg-light;
    transition: $transition;
    padding: 26px;
    border-radius: 10px;
    position: relative;

    &.account-controls__content-height{
      height: 100%;
    }

    & + .account-controls__content{
      margin-top: 20px;
    }
  }

  &__title{
    display: block;
    margin-bottom: 13px;
    line-height: 1;
    font-size: 17px;
    letter-spacing: 0.01em;
  }
  &__balance{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    &-icon{
      width: 34%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../img/bg/bg-admin-logo.svg) no-repeat center $bg-base;
      -webkit-background-size: cover;
      background-size: cover;
      color: $primary;
      height: 64px;
      border-radius: 10px;
      margin-top: 12px;
      margin-bottom: 12px;
      position: relative;

      &:before{
        @include pseudo();
        top: -12px;
        bottom: -12px;
        left: 12px;
        right: 12px;
        z-index: -1;
        background: $input-border;
        border-radius: 9px;
      }

      .icon{
        display: block;
        width: 32px;
        height: 32px;
      }
    }
    &-txt{
      width: 66%;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }
    &-title{
      margin-bottom: 13px;
      display: block;
      font-size: 12px;
      letter-spacing: 0.1em;
      line-height: 1;
      text-transform: uppercase;
      color: $color-light;
      width: 100%;
      align-items: center;
    }
    &-sum{
      display: block;
      font-size: 30px;
      line-height: 1;
      font-weight: 700;
    }
    &-btn{
      border-radius: 50%;
      color: #fff;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 21px;
      padding: 6px;

      .icon{
        display: block;
        width: 100%;
        height: 100%;
        transform: rotate(90deg);
      }

      &:hover{
        background: darken($primary,10%);
        color: #fff;
      }
    }
  }

  &__sutitle{
    color: $color-light;
    margin: 13px 0 26px;
    display: block;
    font-size: 14px;
    line-height: 1;
  }
  .progress{
    height: 8px;
    background: #353535;
    border-radius: 8px;
  }

  .btn{
    margin-top: 26px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  &__numbers{
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $color-light;
    line-height: 1;

    span{
      color: $color-base;
    }
  }
  &__progress{
    position: absolute;
    bottom: 0;
    left: 3px;
    right: 3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    background: none;
    height: 3px;

    &-item{
      position: absolute;
      background: $primary;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }

  body.day-mode & {
    hr{
      border-color: #C1C2D1;
    }
    .progress{
      background: #D9DAE3;
    }
    &__content{
      background: $bg-day-light;
    }
    &__sutitle{
      color: $color-day-lighter;
    }
    &__balance{
      &-icon{
        background: url(../img/bg/bg-admin-logo-day.svg) no-repeat center #E8E8ED;
        -webkit-background-size: cover;
        background-size: cover;
        box-shadow: 0px 0px 4px rgba(200, 200, 223, 0.5);

        &:before{
          background: rgba(129, 134, 164, 0.25);
        }
      }
      &-title{
        color: $color-day-lighter;
      }
    }
    &__numbers{
      color: $color-day-lighter;

      span{
        color: $color-day-base;
      }
    }
  }

  @media (max-width: 1720px){
    &__col{
      width: 60%;

      &.account-controls__col--narrow{
        width: 40%;
      }
      &.account-controls__col--wide{
        width: 100%;
      }
    }
  }
  @media (max-width: 991px){
    &__col{
      width: 100%;

      &.account-controls__col-desktop{
        display: none;
      }

      &.account-controls__col-mobile{
        display: block;

        .account-controls__content{
          padding-top: 13px;
          padding-bottom: 13px;
          border-radius: 5px;
        }
      }

      &.account-controls__col--narrow{
        width: 100%;
      }
      &.account-controls__col--wide{
        width: calc(100% + 60px);
        margin-left: -30px;
        margin-right: -30px;
        padding-top: 50px;
        padding-bottom: 50px;

        .account-controls__content{
          border-radius: 30px;
          padding-top: 99px;
        }
        .account-controls__title{
          margin-top: 42px;
        }
      }
    }
    &__balance{
      &-settings{
        color: $primary;
        position: absolute;
        right: -26px;
        top: 50%;
        margin-top: -30px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(71, 114, 214, 0.15);
        border: 1px solid rgba(71, 114, 214, 0.2);
        box-sizing: border-box;
        border-radius: 0px 5px 5px 0px;

        .icon{
          display: block;
          width: 24px;
          height: 24px;
        }
      }
      &-icon{
        width: 55px;
        height: 34px;
        margin: 0;

        &:before{
          display: none;
        }

        .icon{
          width: 17px;
          height: 17px;
        }
      }
      &-txt{
        width: calc(100% - 55px);
        padding-left: 17px;
      }
      &-sum{
        display: block;
        font-size: 17px;
        line-height: 1;
        font-weight: 400;
        color: $color-light;

        span{
          color: $color-base;
        }
      }
    }

    body.day-mode &{
      &__balance{
        &-sum{
          color: $color-day-lighter;

          span{
            color: $color-day-base;
          }
        }
      }
    }
  }
  @media (max-width: 413px){
    &__col{
      &.account-controls__col--wide{
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }

}

.chart-block{
  display: block;
  position: relative;
  height: 256px;

  &:before,&:after{
    @include pseudo();
    top: 0;
    bottom: 30px;
    background: linear-gradient(90deg, rgba(44, 44, 44, 0) 17.57%, #2C2C2C 104.44%);
    width: 16.5%;
    pointer-events: none;
    z-index: 1;
  }
  &:before{
    left: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  &:after{
    right: 0;
  }

  &__controls{
    @include listReset;
    top: 40px;
    left: 0;
    position: absolute;
    font-size: 14px;
    line-height: 1;

    a{
      display: inline-block;
      vertical-align: top;
      border-bottom: 1px dashed #5E5F61;
      color: $color-base;
      position: static;

      &:hover{
        border-color: transparent;
      }

    }

    li{
      margin: 0;
      position: static;

      & + li{
        margin-top: 40px;
      }
      & + li.chart-block__controls-link-icon{
        margin-top: 20px;
      }

      &.active{
        a{
          color: $color-light;
          border-color: transparent;
        }

        & + li{
          a:after{
            @include pseudo();
            bottom: 0;
            left: 50%;
            width: 14px;
            height: 14px;
            margin-left: -7px;
            cursor: pointer;
            opacity: 0;
            background: $bg-light;
            transition: $transition-base;
          }
        }
      }

      &.chart-block__controls-link-icon{
        text-align: center;
        pointer-events: none;
        a{
          border: none;
          color: $primary;
        }
        .icon{
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  &__select{
    position: absolute;
    top: 26px;
    right: 26px;
    width: 208px;
    z-index: 2;

    .btn{
      margin: 0;
      background: #2C2C2C !important;
      border-color: #2C2C2C;
      box-shadow: 0px 2px 15px 5px rgba(34, 34, 34, 0.4);
    }
    .dropdown-toggle::after {
      color: $color-light;
    }
  }
  &__wrapper{
    position: relative;
    padding-left: 65px;
  }

  body.day-mode &{
    &:before,&:after{
      background: linear-gradient(90deg, rgba(#E3E4EA, 0) 17.57%, #E3E4EA 104.44%);
    }

    &__controls{
      a{
        border-color: rgba(71, 114, 214, 0.4);
        color: $color-day-base;

        &:hover{
          border-color: transparent;
        }

      }

      li{
        &.active{
          a{
            color: $color-day-light;
            border-color: transparent;
          }

          & + li{
            a:after{
              background: none;
            }
          }
        }
        &.chart-block__controls-link-icon{
          a{
            color: $primary;
          }
        }
      }
    }

    &__select{
      .btn{
        background: #E4E5EB !important;
        border-color: #E4E5EB;
        box-shadow: 0px 2px 8px 4px rgba(210, 211, 224, 0.5);
      }
      .dropdown-toggle::after {
        color: $color-day-lighter;
      }
    }
  }

  @media (max-width: 991px){
    margin: 0 -26px;
    &:before,&:after{
      display: none;
    }
    &__wrapper{
      position: static;
      padding-left: 0;
    }
    &__select{
      top: 126px;
    }
    &__controls{
      @include listReset;
      top: 0;
      left: 0;
      right: 0;
      height: 100px;
      border-bottom: 1px solid #353535;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px;

      a{
        border-bottom: 0;
        padding: 0 32px;
        height: 48px;
        line-height: 48px;
        border-radius: 5px;
        color: $color-light;
      }

      li{
        margin: 0;
        position: static;

        &:first-child{
          order: 1;
          margin-left: auto;
        }

        & + li{
          margin-top: 0;
        }


        &.active{
          a{
            color: $color-base;
            background: $primary;
            border-color: transparent;
          }

          & + li{
            a:after{
              display: none;
            }
          }
        }

        &.chart-block__controls-link-icon{
          display: none;
        }
      }
    }
  }
  @media (max-width: 413px){
    &__controls{
      padding: 0 15px;
    }
  }
}
.doughnut-block{
  position: relative;
  height: 122px;
  margin-bottom: 22px;
  margin-top: -2px;

  &__labels{
    display: flex;
    justify-content: space-between;
    @include listReset;
    align-items: center;
    font-size: 12px;
    line-height: 11px;
    margin-bottom: -2px;

    li{
      position: relative;
      padding-left: 20px;
      color: $color-light;

      &:before{
        @include pseudo();
        top: 50%;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-top: -5px;
        background: $input-border;
      }

      &:first-child:before{
        background: $primary;
      }
    }
  }

  &__title{
    position: absolute;
    @include center(both);
    text-align: center;
    display: block;
    font-size: 10px;
    line-height: 1;
    color: $color-light;
    span{
      display: block;
      font-size: 12px;
      margin-bottom: 6px;
      color: $color-base;
    }
  }

  body.day-mode &{
    &__labels{
      li{
        color: $color-day-lighter;

        &:before{
          background: #DEDEE3;
        }

        &:first-child:before{
          background: $primary;
        }
      }
    }

    &__title{
      color: $color-day-lighter;
      span{
        color: $color-day-base;
      }
    }
  }
}

.cards-slider{
  overflow: visible;
  margin: -20px 0 0;

  &__item{
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    background: $bg-light;
    position: relative;

    .icon{
      color: $bg-base;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  &__title{
    display: block;
    font-size: 14px;
    line-height: .75;
    margin-bottom: 21px;
  }
  &__number{
    font-size: 32px;
    line-height: .78;
    color: $color-light;
    display: block;

    small{
      display: block;
      margin-top: 12px;
      font-size: 12px;
    }
  }

  &__rate{
    display: flex;
    height: 33px;
    margin: 14px -1px -8px;
    align-items: flex-end;

    &-item{
      width: calc(100% / 7);
      flex-grow: 1;
      margin: 0 1px;
      background: rgba(#fff,.08);
      border-radius: 1.99766px 1.99766px 0px 0px;
      transition: $transition-base;

      &:hover{
        background: $primary;
      }
    }
  }

  .swiper-slide{
    height: auto;
  }

  .cards-slider__pag.swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 35px;
  }
  .swiper-pagination-bullet{
    background: $bg-light;
    opacity: 1;

    &-active{
      background: $primary;
    }
  }

  &-admin{
    margin-top: 0;
    .cards-slider__item{
      text-align: center;
      padding: 26px 38px;
    }
    .cards-slider__title{
      font-size: 17px;
    }
    .cards-slider__rate{
      margin-bottom: -16px;
      margin-top: 20px;
    }
    .swiper-slide{
      height: auto;
    }

    .cards-slider__pag.swiper-pagination {
      position: relative;
      bottom: 0;
      margin-top: 35px;
    }
    .swiper-pagination-bullet{
      background: $bg-light;
      opacity: 1;

      &-active{
        background: $primary;
      }
    }

    body.day-mode &{
      .swiper-pagination-bullet{
        background: #C3C4D2;
        &-active{
          background: $primary;
        }
      }
    }
  }

  body.day-mode &{
    .swiper-pagination-bullet{
      background: #C3C4D2;
      &-active{
        background: $primary;
      }
    }
  }

  @media (max-width: 1499px) {
    .swiper-slide{
      width: 250px;
    }
    &-admin{
      .swiper-slide{
        width: 280px;
      }
    }
  }
}

.confirmation-slider{
  overflow: visible;

  &__item{
    padding: 29px 26px 25px;
    height: 100%;
    border-radius: 10px;
    background: $bg-light;
    position: relative;

    .btn-secondary{
      display: block;
      width: 100%;
      font-size: 13px;
      letter-spacing: 0.05em;
      font-weight: 400;
      margin-top: 26px;
    }
    .main-item__head-title{
      margin-bottom: 26px;
    }
    .main-item__head-title-icon{
      width: 79px;
      height: 79px;
    }
    .main-item__head-title-txt{
      width: calc(100% - 79px);
      padding-left: 20px;
      font-size: 14px;

      span{
        display: block;
        font-size: 17px;
        line-height: 1;
        margin-bottom: 14px;
      }
      p{
        color: $color-light;
      }
    }
  }

  &__rate{
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    width: 73px;
    height: 52px;
    font-size: 14px;
    color: $color-light;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;

    &:before{
      @include pseudo();
      bottom: 0;
      left: 0;
      width: 106px;
      height: 106px;
      background: $input-border;
      border-radius: 50%;
    }

    &-txt{
      position: relative;
      z-index: 1;

      .icon{
        width: 10px;
        height: 10px;
        margin-left: 6px;
        margin-right: -10px;
        margin-top: -1px;
      }
    }
  }

  .swiper-slide{
    height: auto;
  }

  .confirmation-slider__pag.swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 35px;
  }
  .swiper-pagination-bullet{
    background: $bg-light;
    opacity: 1;

    &-active{
      background: $primary;
    }
  }

  body.day-mode &{
    .swiper-pagination-bullet{
      background: #C3C4D2;
      &-active{
        background: $primary;
      }
    }
  }

  @media (max-width: 1499px) {
    .swiper-slide{
      width: 250px;
    }
  }
  @media (max-width: 374px) {
    &__item{
      padding: 29px 15px 25px;
      .main-item__head-title-icon{
        width: 50px;
        height: 50px;
        padding: 13px;
      }
      .main-item__head-title-txt{
        width: calc(100% - 50px);
        padding-left: 15px;
        font-size: 10px;

        span{
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.tariff-slider{
  overflow: visible;
  margin: -6px 0 0;

  &__item{
    height: 100%;
    position: relative;
    margin: 0;
    width: 100%;
    display: block;

    &-txt{
      background: url(../img/bg/tariff.svg) no-repeat center;
      -webkit-background-size: cover;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px;
      border-radius: 10px;
      height: 100%;
      position: relative;
      font-size: 19px;
      line-height: 1;
      transition: $transition-base;
      overflow: hidden;
      opacity: .3;

      &:before{
        @include pseudo();
        background: $primary;
        width: 60px;
        height: 60px;
        transform: rotate(45deg);
        top: -31px;
        right: -33px;
        transition: $transition-base;
        opacity: 0;
      }
      &:after{
        @include pseudo();
        border: 2px solid #222222;
        width: 7px;
        height: 9px;
        transform: rotate(45deg);
        border-top: 0;
        border-left: 0;
        top: 6px;
        right: 8px;
        transition: $transition-base;
        opacity: 0;
      }
    }
    &-title{
      background: #222222;
      border-radius: 2px;
      display: block;
      margin-bottom: 20px;
      padding: 0 10px;
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #fff;
    }

    input{
      display: none;

      &:checked + .tariff-slider__item-txt{
        box-shadow: inset 0 0 0 1px $primary;
        opacity: 1;

        &:before,&:after{
          opacity: 1;
        }
      }
    }
  }

  .swiper-slide{
    height: auto;
    width: 226px;
  }

  .tariff-slider__pag.swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 35px;
  }
  .swiper-pagination-bullet{
    background: $bg-light;
    opacity: 1;

    &-active{
      background: #fff;
    }
  }
  .swiper-button-next.tariff-slider__next{
    display: none;
  }

  body.day-mode &{
    .swiper-pagination-bullet{
      background: #C3C4D2;
      &-active{
        background: $primary;
      }
    }
  }

  @media (min-width: 992px) {
    overflow: hidden;
    margin-right: -40px;
    padding-right: 40px;
    &.swiper-container{
      width: calc(100% + 40px);
    }
    &:before{
      @include pseudo();
      width: 100px;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background: linear-gradient(90deg, rgba(34,34,34,0) 0%, rgba(34,34,34,1) 60%);
      z-index: 2;
    }
    .swiper-slide{
      width: 200px;
    }
    &__item{
      &-txt{
        opacity: 1;
      }
    }
    .tariff-slider__pag.swiper-pagination {
      display: none;
    }
    .swiper-button-next.tariff-slider__next{
      display: block;
      color: $primary;
      width: 14px;
      height: 14px;
      right: 0;
      text-align: center;
      margin-top: -7px;
      transition: $transition-base;

      &:after{
        font-size: 14px;
        line-height: 1;
        display: block;
        font-weight: 700;

      }

      &:focus{
        outline: none;
      }
    }
  }
}

.account-slider{
  overflow: visible;

  .swiper-slide{
    height: auto;
    width: 57%;
    min-width: 220px;

    .account-controls__content{
      padding-top: 23px;
      padding-bottom: 22px;
    }
  }

  .account-slider__pag.swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 35px;
  }
  .swiper-pagination-bullet{
    background: $bg-light;
    opacity: 1;

    &-active{
      background: #fff;
    }
  }

  body.day-mode &{
    .swiper-pagination-bullet{
      background: #C3C4D2;
      &-active{
        background: $primary;
      }
    }
  }
}

.swiper-container{
  width: 100%;
}

.col-support-wide{
  flex: 0 0 79.7%;
  max-width: 79.7%;

  @media (max-width: 1499px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-support-narrow{
  flex: 0 0 20.3%;
  max-width: 20.3%;

  @media (max-width: 1499px) {
    display: none;
  }
}
.col-support__list{
  background: $bg-light;
  border-radius: 10px;
  padding: 32px;
  ul{
    list-style: circle;
    font-size: 14px;
    line-height: 1.57;
    margin: 0;
    padding-left: 16px;

    li + li{
      margin-top: 17px;
    }
  }
  &-title{
    font-size: 18px;
    margin-bottom: 30px;
    display: block;
    line-height: 14px;
  }
  &-footer{
    display: block;
    text-align: center;
    padding: 32px;
    margin: 32px -32px -32px;
    font-size: 14px;
    line-height: 1.57;
    border-top: 1px solid $input-border;
  }

  body.day-mode &{
    background: $bg-day-light;

    ul{
      color: $color-day-lighter;
    }

    &-footer{
      border-color: #D3D4E2;
      color: $color-day-lighter;
    }
  }
}

.col-support__links{
  &-row{
    display: flex;
    margin: -10px;
  }
  &-wide{
    padding: 10px;
    width: 75%;
  }
  &-narrow{
    width: 25%;
    padding: 10px;

    .col-support__links-item{
      align-items: center;
      text-align: center;
      padding: 15px;
    }
  }
  &-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    background-color: $bg-light;
    border-radius: 10px;
    overflow: hidden;
    font-size: 14px;
    padding: 45px 60px;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 200px;
    color: $color-base;

    &:hover{
      color: $color-base;
    }

    p{
      opacity: .7;
    }
  }
  &-title{
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 22px;

    .icon{
      width: 12px;
      height: 12px;
      vertical-align: middle;
      margin-left: 10px;
    }
  }

  @media (max-width: 1499px) {
    &-wide{
      width: 100%;
    }
    &-narrow{
      display: none;
    }
  }
  @media (max-width: 1279px) {
    &-item{
      padding: 45px 30px;
      margin: 0 -30px;
    }
  }
  @media (max-width: 413px) {
    &-item{
      padding-left: 15px;
      padding-right: 15px;
      margin: 0 -15px;
    }
  }

  body.day-mode &{
    &-item{
      background-color: $bg-day-light;
      color: $color-day-base;

      &:hover{
        color: $color-day-base;
      }
    }
  }
}

.support-slider{
  margin-top: 20px;
  &__link{
    padding: 11px 26px;
    height: 100%;
    border-radius: 10px;
    background: $bg-light;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-icon{
      color: $primary;
      background: $input-border;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      .icon{
        width: 26px;
        height: 26px;
      }
    }
    &-title{
      width: calc(100% - 50px);
      padding-left: 20px;
      font-size: 16px;
      color: $color-base;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon{
        color: $primary;
        width: 12px;
        height: 12px;
      }
    }
  }

  .swiper-slide{
    height: auto;

    &.support-slider-hidden{
      display: none;
    }
  }

  .support-slider__pag.swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 35px;
  }
  .swiper-pagination-bullet{
    background: $bg-light;
    opacity: 1;

    &-active{
      background: $primary;
    }
  }

  @media (max-width: 1499px) {
    overflow: visible;
    .swiper-slide{
      width: 290px;

      &.support-slider-hidden{
        display: block;
      }
    }
  }

  body.day-mode &{
    &__link{
      background: $bg-day-light;
      &-icon{
        background: #D6D7DF;
      }
      &-title{
        color: $color-day-base;
      }
    }

    .swiper-pagination-bullet{
      background: #C3C4D2;
      &-active{
        background: $primary;
      }
    }
  }

}

.col-support-info{
  position: relative;
  padding-left: 454px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 40px;
  background: $bg-light;
  border-radius: 10px;

  .btn{
    margin-right: 30px;
  }
  &__img{
    position: absolute;
    top: -22px;
    bottom: 0;
    left: 0;
    width: 454px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img{
      display: block;
      height: 100%;
      transition: $transition-base;

      &.col-support-info__img-d{
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }
  }
  &__title{
    font-size: 24px;
    font-weight: 500;
    display: block;
    line-height: 1;
    margin-bottom: 24px;
  }
  &__list{
    @include listReset;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 16px;
    margin-bottom: 15px;

    &-txt{
      color: $color-light;
      margin-right: 5px;
    }

    li{
      margin-right: 80px;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 991px) {
    padding: 25px 30px 32px;
    margin-left: -30px;
    margin-right: -30px;

    .btn{
      position: relative;

      &.btn-secondary{
        display: none;
      }
    }

    &__img{
      position: absolute;
      top: -22px;
      right: -50px;
      left: auto;
      bottom: 0;
      width: 280px;
      img{
        display: block;
        height: 100%;
        transform: rotateY(180deg);
      }
    }

    &__title{
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 27px;
      position: relative;
    }
    &__list{
      font-size: 14px;
      line-height: 1;
      margin-bottom: 24px;
      position: relative;

      li{
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 413px) {
    padding: 25px 15px 32px;
    margin-left: -15px;
    margin-right: -15px;
  }

  body.day-mode &{
    background: $bg-day-light;

    &__list{
      &-txt{
        color: $color-day-lighter;
      }
    }
    &__img{
      img{
        opacity: 0;

        &.col-support-info__img-d{
          opacity: 1;
        }
      }
    }
  }
}

.main-admin.main-admin--chat{
  padding: 0;
}

.chat{
  display: block;
  position: relative;
  height: calc(100vh - 125px);
  margin: 0 -30px;

  @media (max-width: 991px){
    margin: 0 -15px;
  }
  @media (min-width: 992px){
    height: calc(100vh - 125px);
    margin-left: 0;
    margin-right: 0;
  }

  .form-control__field{
    margin: 0;
    width: 100%;

    .form-control{
      background: #3F3F3F !important;
    }

    .btn-icon{
      position: absolute;
      width: 18px;
      height: 18px;
      border: 0;
      color: $color-light;
      top: 50%;
      margin-top: -9px;
      right: 14px;
      padding: 0;
      background: none;

      .icon{
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
      }

      &:hover,&:active{
        color: $primary;
        background: none;
      }

    }
  }

  &-sidebar{
    height: 100%;
    border-radius: 30px;
    background: $input-border;
    @media (min-width: 992px){
      width: 40%;
    }
    @media (min-width: 1750px){
      width: 33%;
    }
    &__title{
      height: 158px;
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (min-width: 1450px){
        padding: 0 55px;
      }
    }

    &__content-wrapper{
      height: -webkit-calc(100% - 158px);
      height: -moz-calc(100% - 158px);
      height: calc(100% - 158px);
      overflow: auto;
      scrollbar-color: $input-border $primary;
      scrollbar-width: thin;
      scrollbar-height: thin;
      padding: 0 30px;
      @media (min-width: 1450px){
        padding: 0 55px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: $input-border;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: $primary;
      }
    }
    &__message{
      @include flexbox(flex-start,flex-start);
      cursor: pointer;
      position: relative;
      padding: 26px 26px 26px 95px;
      background: $bg-light;
      border-radius: 10px;
      transition: $transition-base;
      margin-bottom: 20px;

      &-number{
        position: absolute;
        background: $primary;
        color: #fff;
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        border-radius: 9px;
        height: 18px;
        padding: 0 4px;
        text-align: center;
        min-width: 18px;
        font-size: 9px;
        line-height: 18px;
        top: 42px;
        left: -9px;
      }

      &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 4px;
        right: 4px;
        background: $primary;
        opacity: 0;
        transition: $transition-base;
        height: 3px;
        pointer-events: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .badge{
        margin-top: 26px;
        margin-right: 20px;
      }

      &:hover{
        opacity: .5;
      }

      .header-admin__profile-avatar{
        position: absolute;
        top: 26px;
        left: 26px;
        width: 50px;
        height: 50px;
      }

      .author{
        margin: -25px 0 0 0;
        width: 50px;
        left: 30px;
        position: absolute;
        top: 50%;
        &__img{
          width: 50px;
          height: 50px;
          margin: 0;
        }

        @media (max-width: 991px){
          left: 15px;
        }
      }

      &-author{
        font-size: 16px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1;
        margin-bottom: 15px;
        width: 100%;
      }
      &-inbox{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-light;
        font-size: 12px;
        display: block;
        display: block;
        position: relative;
        font-size: 14px;
        line-height: 1;
        width: 100%;
      }

      &.current{
        opacity: 1;
        &:before{
          opacity: 1;
        }
      }
      &-read{
        .chat-sidebar__message-inbox{
          &:after{
          }
        }
      }

      &-time{
        font-size: 12px;
        color: #9CA1A5;
        position: absolute;
        right: 30px;
        top: 50%;
        white-space: nowrap;
        margin-top: -19px;

        @media (max-width: 991px){
          right: 15px;
        }
      }
    }
  }

  &-content{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: $transition-base;
    padding: 0 30px;

    @media (min-width: 1450px){
      padding: 0 55px;
    }

    &.current{
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }

    &__wrapper{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100%;
      z-index: 1;
      transform: translateX(100%);
      border-radius: 5px;
      background: $bg-light;


      @media (min-width: 992px){
        transform: translateX(0);
        left: 40%;
        background: none;
      }
      @media (min-width: 1750px){
        left: 33%;
      }
      &.active {
        transform: translateX(0);
      }
    }

    &__back{
      width: 24px;
      height: 24px;
      position: absolute;
      left: 30px;
      top: 67px;
      z-index: 9;

      &:before {
        @include pseudo();
        width: 12px;
        height: 12px;
        border: 2px solid $primary;
        border-top: 0;
        border-left: 0;
        transform: rotate(135deg);
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
      }

      @media (min-width: 992px){
        display: none;
      }
    }
    &__topbar{
      opacity: 1;
      height: 158px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      border-bottom: 1px solid $input-border;

      &-controls{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 30px;
          color: $color-light;
          font-size: 14px;

          .badge{
            background: none;
            margin-top: 5px;
          }
        }
      }

      &-title{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .header-admin__profile-avatar{
          width: 50px;
          height: 50px;
        }

        &-txt{
          width: calc(100% - 50px);
          padding-left: 20px;
        }
        &-name{
          display: inline-block;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 500;
          margin-right: 10px;
        }
        &-tag{
          display: inline-block;
          vertical-align: middle;
          background: #353535;
          border-radius: 4px;
        }
        p{
          width: 100%;
          color: #999B9F;
          font-size: 15px;
          margin-top: 13px;
          line-height: 1;
        }
      }


      @media (max-width: 991px){
        &-controls{
          display: none;
        }
        padding-left: 30px;
      }

    }

    &__bottombar{
      border-radius: 5px;
      height: 97px;
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
  }

  &__zone{
    height: calc(100% - 257px);
    overflow: auto;
    scrollbar-color: $input-border $primary;
    scrollbar-width: thin;
    scrollbar-height: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: $input-border;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: $primary;
    }

    &-block{
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 991px){
        padding: 15px;
      }
    }
  }

  &__message{
    max-width: 100%;
    min-width: 60%;
    position: relative;

    &-info{
      display: block;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 15px;

      span{
        color: $color-light;
      }
    }

    &-content{
      position: relative;
      padding: 0 70px;
      display: flex;
      flex-direction: column;

      .header-admin__profile-avatar{
        position: absolute;
        width: 50px;
        height: 50px;
        top: 0;

        @media (max-width: 991px){
          display: none;
        }
      }

      &-txt{
        font-size: 15px;
        line-height: 1.6;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;

        & + .chat__message-content-txt{
          margin-top: 20px;
        }

        p{
          margin-top: 0;
        }

        img{
          display: block;
          max-width: 95px;
          border-radius: 5px;

          * + &{
            margin-top: 10px;
          }
        }

        @media (max-width: 991px){
          font-size: 14px;
          padding: 9px 10px;
        }
      }

      &-img{
        display: block;
        max-width: 95px;
        border-radius: 5px;

        img{
          max-width: 100%;
        }
        * + &{
          margin-top: 10px;
        }
      }

      .author__img{
        width: 45px;
        height: 45px;
        position: absolute;
        bottom: 0;

        @media (max-width: 991px){
          display: none;
        }
      }
    }

    &-time{
      position: absolute;
      font-size: 13px;
      font-weight: 300;
      bottom: 0;
      color: #9CA1A5;
      letter-spacing: 0.34px;
      white-space: nowrap;

      @media (max-width: 991px){
        font-size: 12px;
      }

      &:before{
        @include pseudo();
        width: 12px;
        height: 12px;
        bottom: 100%;
        background-size: contain;
      }
    }
    &-date{
      letter-spacing: 0.36px;
      display: block;
      margin-bottom: 25px;
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      color: #9CA1A5;
      width: 100%;
    }

    & + .chat__message-date{
      margin-top: 35px;
    }

    & + .chat__message{
      margin-top: 50px;
    }

    &-inbox{
      margin-left: 0;
      margin-right: auto;

      .chat__message-content{
        align-items: flex-start;

        .header-admin__profile-avatar{
          left: 0;
        }
      }

      .chat__message-content-txt{
        background: $primary;
        border-radius: 0px 30px 30px 30px;
      }
      .author__img{
        left: 0;
      }
      .chat__message-time{
        right: 0;

        &:before{
          left: 0;
        }
      }

      @media (max-width: 991px){
        .chat__message-content{
          padding-left: 0;
          padding-right: 55px;
        }
      }
    }
    &-outbox{
      margin-right: 0;
      margin-left: auto;

      .chat__message-content{
        align-items: flex-end;

        .header-admin__profile-avatar{
          right: 0;
        }
      }

      .chat__message-content-txt{
        background: #353535;
        border-radius: 30px 0px 30px 30px;
      }
      .author__img{
        right: 0;
      }
      .chat__message-time{
        left: 0;

        &:before{
          right: 0;
        }
      }

      @media (max-width: 991px){
        .chat__message-content{
          padding-right: 0;
          padding-left: 55px;
        }
      }
    }

    &-seen{
      .chat__message-time{
        &:before{
        }
      }
    }
  }

}

.attacks-item {

  padding: 44.5px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__info {

    width: calc(100% - 105px - 50px);

    &-title {

      font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 1;
      color: #FFFFFF;

      & + * {

        margin-top: 26px;

      }

    }

    &-caption {

      font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1;
      color: #FFFFFF;

    }

  }

  &__chart {

    width: 105px;

    &-status {

      display: flex;
      align-items: center;

      &-value {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 1;
        color: #FFFFFF;

        & + * {

          margin-left: 10px;

        }

      }

      &-percent {

        position: relative;
        padding-left: 24px;
        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 1;
        color: #F44444;

        &:before {

          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
          background:url("../img/arrow-growth.svg") center no-repeat;
          width: 8px;
          height: 14px;
          background-size: 8px 14px;

        }

        &_type-grow {

          &:before {

            transform: translateY(-50%);

          }

        }

      }

      & + * {

        margin-top: 20px;

      }

    }

  }

  & + * {

    margin-top: 30px !important;

  }

}

.stats-chart .chart-block__wrapper {
  padding-left: 0px;
}

.panel-item {

  border: 1px solid #353535;
  box-sizing: border-box;
  border-radius: 10px;
  background: transparent;

}

table.table.table--attack-type tr td:last-child {
  width: 21%;
}

.stats-table {

  height: 100%;

  &__row {

    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  &__label {

    display: flex;
    align-items: center;

    &-round {

      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: transparent;
      position: relative;

      &:after {

        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background: transparent;

      }

      &_color-yellow {

        background: rgba(246, 200, 63, 0.1);

        &:after {

          background: #F6C83F;

        }

      }

      &_color-green {

        background: rgba(0, 196, 150, 0.1);

        &:after {

          background: #00C496;

        }

      }

      &_color-red {

        background: rgba(251, 95, 108, 0.1);

        &:after {

          background: #FB5F6C;

        }

      }

      &_color-blue {

        background: rgba(71, 114, 214, 0.1);

        &:after {

          background: #4772D6;

        }

      }

      &_color-gray {

        background: rgba(153, 155, 159, 0.1);

        &:after {

          background: #999B9F;

        }

      }

      &_color-violet {

        background: rgba(128, 132, 216, 0.1);

        &:after {

          background: #8084D8;

        }

      }

      & + * {

        margin-left: 32px;

      }

    }

    &-text {

      background: #353535;
      border-radius: 3px;
      min-width: 88px;
      height: 25px;
      font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 1;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #F44444;
      padding: 7px;
      justify-content: center;

    }

    &-link {

      font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1;
      color: #999B9F;
      position: relative;

      &:after {

        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        right: 0;
        background: rgba(71, 114, 214, 0.4);
        height: 1px;

      }

    }

  }

  &__text {

    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1;
    color: #999B9F;
    text-align: center;

  }

}

.round-chart {

  padding: 23px 80px 24px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__content {

    width: 270px;
    height: 270px;
    position: relative;

    &-value {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > span {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 1;
        color: #999B9F;

        & + * {

          margin-top: 20px;

        }

      }

      &-number {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 1;
        color: #FFFFFF;

        & + * {

          margin-top: 20px;

        }

      }

    }

  }

  &__info {

    width: calc(100% - 270px - 25px);

    ul {

      padding: 0px;
      margin: 0px;

      li {

        & + * {

          margin-top: 33px;

        }

      }

    }

    &-item {

      padding-left: 45px;
      display: flex;
      align-items: center;
      position: relative;

      &-value {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 1;
        color: #FFFFFF;
        min-width: 55px;

        & + * {

          margin-left: 15px;

        }

      }

      &-caption {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 1;
        color: #999B9F;

      }

      &_color-green {

        &:before {

          background: #45D8AD;

        }

      }

      &_color-red {

        &:before {

          background: #FB5F6C;

        }

      }

      &_color-gray {

        &:before {

          background: #999B9F;

        }

      }

      &_color-blue {

        &:before {

          background: #4772D6;

        }

      }

      &_color-yellow {

        &:before {

          background: #F6C83F;

        }


      }

      &:before {

        content: '';
        position: absolute;
        left: 0;
        top:50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;

      }

    }

  }

  &__canvas {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

  }

}

.usual-chart {

  padding: 46px 52px 38px 27px;

  &__canvas {

    width: 100%;
    height: 318px;

  }

}

.stats-chart {
  height: 100%;
}

.tariff-head {

  padding: 60px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  &__bg {

    background: url("../img/tariff-bg.png") center no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

  }

  &__heading {

    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index:3;

    &-row {

      display: flex;
      align-items: center;
      z-index: 3;

    }

    &-title {

      display: flex;
      align-items: center;

      & > * {

        & + * {

          margin-left: 40px;

        }

      }

      &-id {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.5);

      }

      &-name {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 1;
        text-transform: uppercase;
        color: #FFFFFF;
        position: relative;

        &:before {

          content: '';
          position: absolute;
          left: -21px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 20px;
          background: #FFFFFF;

        }

      }

      & + * {

        margin-left: 45px;

      }

    }

    &-date {

      font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1;
      color: #FFFFFF;
      padding-left: 32px;
      position: relative;

      &:before {

        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("../img/clock.svg") center no-repeat;
        background-size: 17px;
        width: 17px;
        height: 17px;

      }

    }

    &-price {

      display: flex;
      align-items: center;

      & > * {

        & + * {

          margin-left: 25px;

        }

      }

      &-caption {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 1;
        color: #FFFFFF;
        z-index: 3;

      }

      &-value {

        & > span {

          &:first-child {

            font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 26px;
            line-height: 1;
            color: #FFFFFF;

          }

          &:last-child {

            font-family: Gotham Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 1;
            color: rgba(255, 255, 255, 0.5);

          }

        }

      }

    }

  }

  &__foot {

    margin-top: 56px;
    display: flex;
    align-items: center;
    z-index:3;

    &-caption {

      font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 1;
      color: #FFFFFF;
      z-index: 3;

      & + * {

        margin-left: 45px;

      }

    }

    &-options {

      display: flex;
      align-items: center;

      & > * {

        & + * {

          margin-left: 40px;

        }

      }

      &-item {

        padding-left: 27px;
        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 1;
        color: #FFFFFF;
        position: relative;

        &:before {

          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background: url("../img/check.svg") center no-repeat;
          width: 12px;
          height: 10px;
          background-size: 12px 10px;

        }

      }

    }

  }

}

.tariff-toggle {

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0 5px;

  &__controls {

    label {

      cursor: pointer;

      input {

        display: none;

        &:checked {

          & + * {

            .tariff-toggle__controls-text {

              &:first-child {

                .tariff-toggle__controls-text-value {

                  font-family: Gotham Pro;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 18px;
                  line-height: 1;
                  color: #999B9F;

                }

              }

            }

            .tariff-toggle__controls-box {

              span {

                left: initial;
                right: 1px;

              }

              & + * {

                .tariff-toggle__controls-text-value {

                  font-family: Gotham Pro;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 1;
                  color: #FFFFFF;

                }

              }

            }

          }

        }

      }

    }

    &-content {

      display: flex;
      justify-content: center;

    }

    &-text {

      &:first-child {

        .tariff-toggle__controls-text-value {


          font-family: Gotham Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 1;
          color: #FFFFFF;

        }

      }

      span {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 1;
        color: #999B9F;

      }

      &-value {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 1;
        color: #999B9F;

      }

    }

    &-box {

      margin: 0 30px;
      width: 60px;
      height: 32px;
      position: relative;
      background: #25CC88;
      border-radius: 100px;

      span {

        width: 30px;
        height: 30px;
        background: #222222;
        left: 1px;
        top: 1px;
        position: absolute;
        border-radius: 50%;

      }

    }

  }

}

.tariff-toggle__controls-text {
  min-width: 155px;
}

.tariff-table {

  &__head {

    display: flex;
    justify-content: space-between;

    &-contact {

      width: 376px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 60px 30px 32px;
      background: #2C2C2C;
      border-radius: 10px;

      &-img {

        width: 112px;

        svg {

          width: 112px;
          height: 99px;

        }

      }

      &-content {

        width: calc(100% - 112px);
        padding-left: 27px;

      }

      &-title {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 1;
        color: #FFFFFF;

        & + * {

          margin-top: 25px;

        }

      }

      &-link {

        a {

          font-family: Gotham Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 1;
          color: #4772D6;

          position: relative;

          &:before {

            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            right: 0;
            height: 1px;
            background: rgba(71, 114, 214, 0.4);

          }

        }

      }

    }

    &-row {

      width: calc(100% - 376px - 45px);
      display: flex;

    }

    &-item {

      width: calc(100% / 4);
      padding: 45px 65px 45px;

      &-name {

        font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 1;
        color: #FFFFFF;

        & + * {

          margin-top: 20px;

        }

      }

      &-price {

        display: flex;
        align-items: center;

        & > * {

          & + * {

            margin-left: 15px;

          }

        }

        &-value {

          font-family: Gotham Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 1;
          color: #FFFFFF;

        }

        &-caption {

          font-family: Gotham Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 1;
          color: #999B9F;

        }

        & + * {

          margin-top: 25px;

        }

      }

      &-status {

        &-current {

          display: flex;

          span {

            background: #2C2C2C;
            border-radius: 5px;
            padding: 15px 20px;
            font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 1;
            text-align: center;
            color: #999B9F;

          }

        }

        &-button {

          button {

            background: rgba(71, 114, 214, 0.1);
            border-radius: 5px;
            padding: 15px 20px;

            span {

              font-family: Gotham Pro;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 1;
              text-align: center;
              color: #4772D6;

            }

          }

        }

      }

      &.active {

        border-radius: 10px 10px 0px 0px;
        background: #4772D6;
        position: relative;

        .tariff-table__head-item-status-button {

          button {

            background: #FFFFFF;
            border-radius: 5px;

            span {

              font-family: Gotham Pro;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 1;
              text-align: center;
              color: #4772D6;


            }

          }

        }

      }

    }

    & + * {

      margin-top: 30px;

    }

  }

  &__body {

    display: flex;
    justify-content: space-between;
    background: #2C2C2C;
    border-radius: 10px;
    padding: 15px  15px 15px 0;

    &-list {

      width: 376px;

      ul {

        display: block;
        list-style: none;
        padding-left: 30px;

        li {

          width: 100%;
          font-family: Gotham Pro;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 1;
          color: #999b9f;
          padding: 25px 0;
          list-style: none;
          height: 75px;
          display: flex;
          align-items: center;

          & + * {

            border-top: 1px solid #353535;

          }

        }

      }

    }

    &-content {

      width: calc(100% - 376px - 45px);
      display: flex;
      background: #222222;
      border-radius: 10px;

    }

    &-item {

      width: calc(100% / 4);

      &-cell {

        z-index: 5;
        width: 100%;
        padding: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        height: 75px;
        align-items: center;
        position: relative;

        span {

          font-family: Gotham Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height:1;
          color: #999B9F;

        }

        svg {

          width: 25px;
          height: 25px;

        }

        & + * {

          &:after {

            content: '';
            position: absolute;
            left: 25px;
            right: 25px;
            top: 0;
            background: #353535;
            height: 1px;

          }


        }

      }

      &.active {

        background: #4772D6;
        position: relative;

        .tariff-table__body-item-cell {

          span {

            font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 1;
            color: #FFFFFF;

          }

          & + * {

            &:after {

              content: '';
              position: absolute;
              left: 25px;
              right: 25px;
              top: 0;
              background: rgba(255, 255, 255, 0.2);
              height: 1px;

            }

          }

        }

        &:after {

          content: '';
          position: absolute;
          top: -46px;
          left: -1px;
          right: -1px;
          height: 45px;
          background: #4772d6;
          height: calc(100% + 46px);


        }

      }

    }

  }

}

.tariff-enterprise {

  background: #2C2C2C;
  border-radius: 10px;
  position: relative;
  padding: 60px 100px 60px 60px;

  &__title {

    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 1;
    color: #FFFFFF;

    & + * {

      margin-top: 30px;

    }

  }

  &__content {

    max-width: 790px;
    position: relative;

    & > p {

      font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 1.6;
      color: #999B9F;

      & + * {

        margin-top: 50px;

      }

    }

    .btn {

      padding: 17px 62px;

    }

  }

  &__image {

    right: 80px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {

      width: 455px;
      height: 447px;

    }

  }

}

.tariff-table__head-row {
  padding-right: 14px;
}

.tariff-table__head-item.active .tariff-table__head-item-price-caption {
  color: rgba(255, 255, 255, 0.5);
}

.tariff-table__body-item + .tariff-table__body-item {
  border-left: 1px solid #353535;
}

.tariff-table__head-item-status-button button {
  padding: 10px 20px;
}

.tariff-head.tariff-head_type-proccess .tariff-head__bg {

  background: url("../img/proccess.png") center no-repeat;
  background-size: cover;

}

.tariff-head__label {

  display: flex;

  span {

    padding: 14px 17px;
    font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 1;
    text-align: center;
    color: #FF754C;
    border: 1px solid #3B3B3B;
    box-sizing: border-box;
    border-radius: 100px;

  }

}

.tariff-head__foot-options-btn {
  z-index: 3;
}

.tariff-head__foot-options-btn {

  & > * {

    padding: 17px 81px;

  }

}

.tariff-head__foot-options-btn {
  position: absolute;
  right: 60px;
  bottom: 39px;
}

.tariff-head__foot-options {
  padding-right: 200px;
}

.tariff-table > * {
  min-width: 1564px;
}

.tariff-table {
  /* width: 1564px; */
  overflow: auto;

  scrollbar-color: $input-border $primary;
  scrollbar-width: thin;
  scrollbar-height: thin;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $input-border;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $primary;
  }

}

.tariff-table__body-item.active:after {
  left: 0px;
  right: -1px;
}

.attacks-item__button {
  width: 100%;
}

.attacks-item {
  flex-wrap: wrap;
}

.attacks-item__button {
  margin-top: 25px;
}

.attacks-item__button > * {
  width: 100%;
}



@media (max-width: 1856px) {

  .tariff-enterprise__content {
    max-width: 590px;
    position: relative;
  }

  .tariff-head__foot {
    max-width: 850px;
    flex-wrap: wrap;
  }

  .tariff-head__foot-options {
    margin-left: 0;
    margin-top: 25px;
    padding: 0;
  }

  .tariff-head__foot {
    max-width: 997px;
    flex-wrap: wrap;
  }



}

@media (max-width: 1720px) {



  .tariff-head__foot-options-btn {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    margin-top: 25px;
  }

  .tariff-head__foot-options {
    flex-wrap: wrap;
  }

  .tariff-head__foot-options-item {
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px;
  }


}

@media (max-width: 1660px) {

  .tariff-enterprise__image {
    display: none;
  }

  .tariff-enterprise__content {
    max-width: 100%;
  }



}

@media (max-width: 1279px) {



}

@media (max-width: 1199px) {

  .usual-chart__canvas {

    height: initial;

  }

}

@media (max-width: 991px) {


  .tariff-head__heading-title-name {
    font-size: 22px;
  }

  .tariff-head__heading-title+* {
    margin-left: 18px;
  }

  .tariff-head {
    padding: 45px;
  }



  .usual-chart__canvas {

    height: initial;

  }

  .chart-block {
    height: initial !important;
  }

  .attacks-item {
    flex-wrap: wrap;
  }

  .attacks-item__info {
    width: 100%;
    text-align: center;
  }

  .attacks-item__chart {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



}

@media (max-width: 768px) {

  .round-chart {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .round-chart__info {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tariff-head.tariff-head_type-proccess {
    padding: 45px;
  }

  .tariff-head.tariff-head_type-proccess {
    padding: 45px;
  }


  .tariff-head__heading-row {
    flex-wrap: wrap;



  }

  .tariff-head__heading-row > * {
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0;
  }


  .tariff-head__heading-title + * {
    margin-top: 30px;
  }

  .tariff-head__heading-row {
    flex-wrap: wrap;
    width: 100%;

    & + * {

      margin-top: 30px;

    }

  }

  .tariff-head__foot-caption {
    display: none;
  }

  .tariff-head__foot-options-item {
    display: none;
  }

  .tariff-head__foot {
    margin-top: 30px;
    width: 100%;
  }

  .tariff-head__foot-options-btn {
    display: flex;
    margin: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .tariff-head__bg {

    background: #4772D6 !important;

  }

  .tariff-head.tariff-head_type-proccess .tariff-head__bg {
    background: #2c2c2c !important;
  }

  .tariff-head__heading {
    flex-wrap: wrap;
  }

  .tariff-head__foot-options {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .tariff-toggle {
    padding: 0;
  }

  .tariff-toggle__controls-text span:not(.tariff-toggle__controls-text-value) {
    display: none;
  }
  .tariff-toggle__controls-box {
    margin: 0 25px;
  }

  .tariff-enterprise {
    padding: 45px 30px;
  }

  .tariff-toggle__controls-text {
    min-width: initial;
  }

  .tariff-toggle__controls-text span {
    font-size: 16px !important;
  }

  .tariff-toggle {
    margin-bottom: -25px;
  }

  .tariff-head__heading-date {
    width: initial;
    margin: 0 auto;
    margin-top: 30px;
  }

  .tariff-head__heading-date {
    font-size: 13px;
  }

  .tariff-head__heading-title-name {

    font-size: 12px;

  }

}

@media (max-width: 576px) {

  table.table.table--attack-type tr td:last-child {
    display: none;
  }

  .round-chart.panel-item {
    padding: 25px;
  }

  .usual-chart.panel-item {
    padding: 25px;
  }

  .stats-table .main-item__table.main-item__table--mobile {
    white-space: initial;
  }

  .stats-table__row {
    flex-direction: column;
    align-items: flex-start;
  }

  .stats-table__text {
    padding-left: 48px;
    margin-top: 12px;
  }

  table.table.table--attack-type .stats-table__row {
    flex-direction: row;
    align-items: center;
  }

  table.table.table--attack-type .stats-table__text {
    margin: 0;
    padding: 0;
  }

  .stats-table__label-link {
    font-size: 11px;
  }


}

@media (max-width: 413px) {

  .tariff-head__heading-title-name {
    font-size: 14px;
  }

  .tariff-toggle__controls-text span {
    font-size: 11px!important;
  }

  .tariff-head__heading-price-value>span:first-child {
    font-size: 18px;
  }



}

.day-mode {

  .tariff-head__bg {
    background: rgba(255,255,255,.3) !important;
  }

  .tariff-head__heading-title-name:before {

    background: #2c3049 !important;

  }

  .tariff-head * {
    color: #2c3049 !important;
  }

  .tariff-toggle *{

    color: #2c3049 !important;

  }

  .tariff-head {

    a.btn.btn-primary {

      color:white !important;

    }



  }

  .tariff-toggle__controls-box span {
    background: #4772d6;
  }

  .tariff-toggle__controls-box {
    background: #e5e5ea;
  }

  .tariff-table__head-contact {
    background: #e4e4eb;
  }


  .tariff-table__head-contact-title {
    color: #2c3049 !important;
  }

  .tariff-table__head-item-name {
    color: #2c3049 !important;
  }

  .tariff-table__head-item-price-value {

    color: #2c3049 !important;

  }

  .tariff-table__body {
    background: #e5e5ea;
  }

  .tariff-table__body-list ul li {
    border-color: #d3d4e2;
  }

   .tariff-table__body-list ul li {
    border-color: #d3d4e2;
    color: #656d82;
  }

  .tariff-table__body-content {
    background: rgba(255,255,255,.3);
  }

  .tariff-table__body-item-cell+:after {

    background:  #d3d4e2;

  }

  .tariff-table__body-item+.tariff-table__body-item {

    border-color: #d3d4e2;

  }

  .tariff-table__body-item-cell span {

    color: #656d82;

  }

  .tariff-table__head-item.active .tariff-table__head-item-name {
    color: #fff !important;
  }

  .tariff-table__head-item.active .tariff-table__head-item-price-value {
    color: #fff !important;
  }

  .tariff-table__head-item-status-current span {
    background: rgba(255,255,255,.3);
    color: #787e91;
  }


  .tariff-enterprise {
    background: rgba(255,255,255,.3);
  }

  .tariff-enterprise__title {

    color: #2c3049 !important;

  }

  .tariff-enterprise__content>p {

    color: #2c3049 !important;

  }

  .tariff-head.tariff-head_type-proccess .tariff-head__bg {

    background: rgba(255,255,255,.3) !important;

  }

}