// Typical
//
//___________________________________

html{
  height: 100%;
}

body{
  position: relative;
  width: 100%;
  height: 100%;
  transition: $transition-base;
  min-width: 320px;

  .main-admin{
    &:before{
      @include pseudo();
      border-radius: inherit;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: $transition-base;
      pointer-events: none;
    }
  }

  &.night-mode{
    color: $color-base;

    .wrapper-admin{
      background: $bg-gradient;
    }
    .main-admin{
      &:before{
        background: $bg-base;
      }
    }
    @media (max-width: 1279px){
      .wrapper-admin{
        background: $bg-base;
      }
    }
  }
  &.day-mode{
    color: $color-day-base;
    background: #E5E5E5;

    .wrapper-admin{
      background: $bg-day-gradient;
    }
    .main-admin{
      &:before{
        background: $bg-day-base;
        mix-blend-mode: multiply;
      }
    }
    @media (max-width: 1279px){
      .wrapper-admin{
        background: linear-gradient(140.01deg, rgba(107, 115, 255, 0.04) 30.44%, rgba(43, 53, 255, 0.08) 77.19%);
      }
      .main-admin{
        &:before{
          opacity: 0;
        }
      }
    }
  }

  @media (max-width: 374px) {
    font-size: 14px;
  }
}


body{
  @media (max-width: 1279px) {
    .main, .header, .footer,.header-admin {
      transition: $transition-base;
    }
    .main{
      overflow-x: hidden;
    }
  }
  &.mobile-body {
    @media (max-width: 1279px) {
      background: $input-border;
      &.day-mode{
        background: linear-gradient(140.01deg, rgba(107, 115, 255, 0.04) 30.44%, rgba(43, 53, 255, 0.08) 77.19%);
      }
      .wrapper{
        height: 100%;
        min-height: 100%;
        overflow: hidden;
        padding-top: 32px;
        padding-bottom: 32px;

        &.wrapper-admin{
          background: none;
          padding-top: 143px;
          
          .main{
            border-top-left-radius: 0;
          }
          .header-admin{
            background: linear-gradient(140.01deg,rgba(107,115,255,.04) 30.44%,rgba(43,53,255,.08) 77.19%);
            border-top-left-radius: 30px;
            top: 32px;
          }
        }
      }
      .main{
        background: $bg-base;
        height: 100%;
        border-radius: 30px;
        padding-top: 130px;

        &.main-admin{
          padding-top: 30px;
          background: linear-gradient(140.01deg,rgba(107,115,255,.04) 30.44%,rgba(43,53,255,.08) 77.19%);
        }
      }
      .header{
        top: 32px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }
      .main, .header, .footer,.header-admin {
        margin-right: -290px;
        margin-left: 290px;
        opacity: .5;
        pointer-events: none;
      }
    }
    @media (max-width: 991px) {
      .main{
        padding-top: 0;
      }
    }
    @media (max-width: 413px) {
      .wrapper{
        &.wrapper-admin{
          padding-top: 113px;
        }
      }
    }
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.wrapper{
  overflow-x: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: $transition-base;
  padding-top: 88px;

  &.wrapper-admin{
    padding: 90px 35px 35px 211px;
    transition: $transition-base;

    .form-control.btn .filter-option-inner-inner {
      padding-top: .95rem;
      padding-bottom: .95rem;
    }
    .bootstrap-select{
      .tag-level:before {
        margin-bottom: -2px;
      }
    }
    .bootstrap-select .dropdown-menu{
      border-color: $input-border;
      font-size: 14px;
    }
    .bootstrap-select .icon{
      border-color: transparent;
      padding: 0;
      border: 0;
      width: 21px;
      height: 21px;
    }
    .bootstrap-select.show .form-control.btn {
      border-color: $input-border-color;
    }
    .form-control__field-flag .bootstrap-select .dropdown-menu .icon {
      border-color: transparent;
      padding: 0;
      width: 23px;
    }
    .form-control.btn:focus {
      border-color: $input-border-color;
    }
    .form-control-with-icon.form-control-lang{
      &::after{
        right: auto;
        position: absolute;
        left: 61px;
      }
      .icon{
        width: 70px;
        margin-right: 24px;
        position: relative;
        img{
          width: 21px;
        }

        &:before{
          @include pseudo();
          right: 0;
          top: 50%;
          height: 24px;
          width: 1px;
          background: $input-border;
          margin-top: -12px;
        }
      }
    }
  }
  @media (max-width: 1279px){
    &.wrapper-admin{
      padding: 110px 0 0;
    }
  }
  @media (max-width: 991px){
    padding-top: 0;
    &.wrapper-admin{
      .col-form{
        max-width: calc(100% + 60px);
        margin-left: -30px;
        margin-right: -30px;
        flex: 0 0 calc(100% + 60px);

        .main-item{
          border-radius: 30px;
        }
      }
    }
  }
  @media (max-width: 413px){
    &.wrapper-admin{
      padding-top: 90px;
      .col-form{
        max-width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        flex: 0 0 calc(100% + 30px)
      }
    }
  }
}

.header,.footer{
  flex: 0 0 auto;
  width: 100%;
}

.main{
  flex: 1 0 auto;
  width: 100%;
  padding: 42px 0 55px;
  position: relative;

  &-row{
    margin-top: -25px;
    margin-bottom: -25px;
    position: relative;

    &>.col{
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &.main-admin{
    padding: 55px;
    border-radius: 30px;
    transition: $transition-base;
  }
  @media (max-width: 1279px){
    &.main-admin{
      padding: 30px 30px 55px;
    }
  }
  @media (max-width: 991px){
    padding-top: 0;
  }
  @media (max-width: 413px){
    padding-top: 0;

    &.main-admin{
      padding-left: 15px;
      padding-right: 15px;
    }
  }

}

.container{
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 413px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

a,input,button{
  transition: $transition;
}

input,button,a,textarea,select,label{
  font-family: $font-family-base;
}

input,
textarea {
  @include input-placeholder {
    font: inherit;
    color: $color-light;
  }
  &:hover{
    @include input-placeholder {
      color: $color-light;
    }
  }
}

textarea{
  scrollbar-color: $input-border $primary;
  scrollbar-width: thin;
  scrollbar-height: thin;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $input-border;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $primary;
  }

  body.day-mode &{
    scrollbar-color: #E9E9EE $primary;
    &::-webkit-scrollbar-track {
      background-color: #E9E9EE;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
    }
  }
}

.link{
  display: inline-block;
  vertical-align: top;

  &-back{
    color: $primary;
    position: relative;

    &:after{
      @include pseudo();
      bottom: 0;
      left: 20px;
      right: 0;
      height: 1px;
      background: $primary;
      opacity: 0;
      transition: $transition;
    }

    &:hover{
      color: $primary;
      &:after{
        opacity: 1;
      }
    }

    .icon{
      width: 10px;
      height: 10px;
      margin-right: 6px;
      vertical-align: baseline;
    }
  }

  &-light{
    color: $color-base;
    border-bottom: 1px solid transparent;

    &:hover{
      color: $primary;
      border-color: rgba($primary,.4);
    }
  }
  &-primary{
    color: $primary;
    border-bottom: 1px solid transparent;
    font-weight: 500;

    &:hover{
      color: $primary;
      border-color: rgba($primary,.4);
    }
  }
  &-base{
    color: $color-base;
    margin: 0 -8px;
    .icon{
      color: $primary;
      margin: 0 8px;
    }

    &:hover{
      color: $primary;
    }
  }
  &-bordered{
    color: inherit;
    border-bottom: 1px solid rgba($primary,.4);

    &:hover{
      color: $primary;
      border-color: rgba($primary,.4);
    }
  }
  &-doc{
    color: $color-light;
    border-bottom: 0;
    .icon{
      color: $primary;
      width: 29px;
      height: 29px;
      margin-right: 20px;
    }
    &__title{
      color: $primary;
      border-bottom: 1px solid rgba($primary,.4);
      transition: $transition-base;
    }

    &:hover{
      color: $color-base;

      .link-doc__title{
        color: $primary;
        border-color: transparent;
      }
    }
  }
  &-icon{
    width: 18px;
    height: 18px;
    color: $color-light;

    &:hover{
      color: $primary;
    }
    .icon{
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }

  &-login{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 50px 0;
    font-size: 22px;
    font-weight: 500;
    text-decoration: none;
    border: 0;
    color: $color-base;

    &__icon{
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 24px;
      color: #fff;
      background: $primary;
      box-shadow: 0px 4px 15px 2px rgba(71, 114, 214, 0.4);
      transition: $transition-base;
    }

    &:hover{
      .link-login__icon{
        box-shadow: none;
      }
    }
  }

  body.day-mode &{
    &-icon{
      color: $color-day-lighter;

      &:hover{
        color: $primary;
      }
    }
  }
}

.btn{
  border-radius: 5px;
  font-weight: 500;
  .icon{
    width: 0.8125em;
    height: 0.8125em;
    margin-right: 10px;
    vertical-align: baseline;
  }
  &-outline-primary{
    padding: .75rem 2rem;

    &.btn-md{
      padding: .719rem 2rem;
      font-size: 13px;
      font-weight: 400;
    }
  }
  &-primary{
    border: 0;
    background-color: transparent;
    background-image: linear-gradient(180deg, #4772D6 0%, #4772D6 50%, #345EC0 74%, #2049A9 100%);
    background-size: 200% 200%;
    background-position: top center;
    transition: $transition-base;
    padding: calc(.75rem + 1px) 2rem;

    &:hover,&:active,&:focus,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active{
      background-position: bottom center;
    }

    &.btn-md{
      padding: .719rem 2rem;
      font-size: 13px;
      font-weight: 400;
    }
  }
  &-secondary{
    border: 0;
    background: $bg-light;
    transition: $transition-base;
    color: $color-light;
    box-shadow: 0px 2px 15px 5px rgba(34, 34, 34, 0.4);
    padding: calc(.75rem + 1px) 2rem;

    &:hover,&:active,&:focus,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active{
      box-shadow: none;
      background: $primary;
      color: $color-base;
    }

    &.btn-md{
      padding: .719rem 2rem;
      font-size: 13px;
      font-weight: 400;
    }

    body.day-mode &{
      background: #E4E4EA;
      box-shadow: 0px 2px 8px 4px rgba(210, 211, 224, 0.5);
      color: $color-day-base;

      &:hover,&:active,&:focus,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active{
        box-shadow: none;
        background: $primary;
        color: $color-base;
      }
    }
  }
  &-light{
    border: 0;
    color: $primary;
    background: #fff;
    padding: calc(.75rem + 1px) 2rem;

    &:hover,&:active,&:focus,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active{
      background: rgba(#fff,.8);
      color: $primary;
    }
  }
  &-icon{
    width: 34px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    vertical-align: middle;

    &.btn-icon-md{
      width: 44px;
      height: 44px;

      .icon{
        width: 16px;
        height: 16px;
      }
    }

    .icon{
      width: 14px;
      height: 14px;
    }

    &-primary{
      background-color: transparent;
      background-image: linear-gradient(180deg, #4772D6 0%, #4772D6 50%, #345EC0 74%, #2049A9 100%);
      background-size: 200% 200%;
      background-position: top center;
      transition: $transition-base;
      color: #fff;

      &:hover{
        background-position: bottom center;
        color: #fff;
      }
    }
    &-secondary{
      background: $bg-light;
      transition: $transition-base;
      color: $color-light;
      box-shadow: 0px 2px 15px 5px rgba(34, 34, 34, 0.4);

      &:hover{
        box-shadow: none;
        background: $primary;
        color: $color-base;
      }

      body.day-mode &{
        color: $primary;
        background: #D2D3E9;
        box-shadow: 0px 0px 6px 2px rgba(200, 200, 223, 0.8);

        &:hover{
          box-shadow: none;
          background: $primary;
          color: $color-base;
        }
      }
    }
  }
  &-tooltip{
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    color: $blue;
    padding: 0;
    background: none;
    outline: none;

    * + &{
      margin-left: 10px;
    }

    &:focus{
      outline: none;
    }

    &:hover{
      color: $darkblue;
    }

    .icon{
      vertical-align: top;
      width: 100%;
      height: 100%;
    }
  }
  &-white{
    background: #fff;
    border-color: #fff;
    color: $primary;

    &:hover{
      color: $primary;
      background-color: #DAE3F7;
      border-color: #DAE3F7;
    }
  }
}

.tag-icon{
  display: inline-block;
  vertical-align: top;
  .icon{
    width: 16px;
    height: 16px;
    color: $color-light;
    vertical-align: text-bottom;
    margin-right: 10px;
  }
  &-info{
    .icon{
      color: $info;
    }
  }
  &-danger{
    .icon{
      color: $danger;
    }
  }
  &-warning{
    .icon{
      color: $warning;
    }
  }
  &-success{
    .icon{
      color: $success;
    }
  }

  body.day-mode &{
    .icon{
      color: $color-day-lighter;
    }
    &-info{
      .icon{
        color: $info;
      }
    }
    &-danger{
      .icon{
        color: $danger;
      }
    }
    &-warning{
      .icon{
        color: $warning;
      }
    }
    &-success{
      .icon{
        color: $success;
      }
    }
  }
}

.tag-level{
  display: inline-block;
  vertical-align: top;
  .icon{
    width: 14px;
    height: 14px;
    color: $color-light;
    vertical-align: text-bottom;
    margin-right: 10px;
  }
  &:before{
    content: '';
    display: inline-block;
    vertical-align: baseline;
    width: 14px;
    height: 14px;
    background: url(../img/icons/icon-fire.svg) no-repeat center;
    -webkit-background-size: contain;
    background-size: contain;
    margin-right: 15px;
  }
  &-info{
    .icon{
      color: $info;
    }
    &:before{
      background-image: url(../img/icons/icon-fire-info.svg);
    }
  }
  &-danger{
    .icon{
      color: $danger;
    }
    &:before{
      background-image: url(../img/icons/icon-fire-danger.svg);
    }
  }
  &-warning{
    .icon{
      color: $warning;
    }
    &:before{
      background-image: url(../img/icons/icon-fire-warning.svg);
    }
  }
  &-success{
    .icon{
      color: $success;
    }
    &:before{
      background-image: url(../img/icons/icon-fire-success.svg);
    }
  }
}

.flag-icon{
  display: inline-block;
  vertical-align: top;
  width: 27px;

  img{
    display: block;
    width: 100%;
  }
}

.tag-online{
  display: inline-block;
  vertical-align: middle;
  background: $bg-light;
  border: 1px solid $input-border;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &:before{
    @include pseudo();
    @include center(both);
    width: 8px;
    height: 8px;
    background: $input-border;
    border-radius: 50%;
  }

  &.active{
    &:before{
      background: $success;
    }
  }

  body.day-mode &{
    background: rgba(195, 196, 210, 0.4);
    border: 0;

    &:before{
      background: $input-day-border;
    }

    &.active{
      &:before{
        background: $success;
      }
    }
  }
}

.path-item,.database-item,.version-item{
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.database-item{
  padding-left: 25px;

  &:after{
    @include pseudo();
    width: 15px;
    height: 15px;
    background: url(../img/icons/check-error.svg) no-repeat center;
    -webkit-background-size: contain;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    left: 0;
  }
  
  &.active{
    &:after{
      background-image: url(../img/icons/check-success.svg);
    }
  }

}

.version-item{
  padding-left: 25px;

  &:after{
    @include pseudo();
    width: 15px;
    height: 15px;
    background: url(../img/icons/check-error.svg) no-repeat center;
    -webkit-background-size: contain;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    left: 0;
  }

  &.active{
    &:after{
      background-image: url(../img/icons/check-success.svg);
    }
  }
}

.form-control{
  font-size: 14px;
  //padding-left: 0;
  //padding-right: 0;
  background: none !important;
  //border-top: 0;
  //border-left: 0;
  //border-right: 0;
  //border-radius: 0;
  border-color: $input-border;
  color: #fff;
  border-radius: 5px;

  &.form-control-2fa{
    font-size: 28px !important;
    letter-spacing: 1em;
    border: 0;
    background: #2C2C2C !important;
    border-radius: 10px !important;
    height: 80px;
    line-height: 80px;
    color: $color-base;
    padding: 0 29px !important;

    &:-moz-placeholder {
      line-height: 80px;
    }
    &::-moz-placeholder {
      line-height: 80px;
    }
  }

  &:focus{
    border-color: $color-base;
    color: #fff;
  }

  &__field{
    position: relative;

    &-txt{
      position: absolute;
      color: inherit;
      width: 14px;
      height: 14px;
      top: 50%;
      margin-top: -7px;
      right: 15px;
      padding: 0;
      z-index: 1;
      font-size: 15px;
      line-height: 14px;
      text-align: center;
      pointer-events: none;
      opacity: .5;
    }

    &-icon{
      position: absolute;
      color: $input-border;
      width: 14px;
      height: 14px;
      top: 50%;
      margin-top: -7px;
      right: 25px;
      padding: 0;
      z-index: 1;
      font-size: 15px;
      line-height: 14px;
      text-align: center;

      .icon{
        width: 100%;
        height: 100%;
        display: block;
      }

      &:hover,&.active{
        color: $primary;
      }
    }

    &.form-control__field-centered{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      min-height: 54px;

      .custom-control{
        margin: 0;
      }
    }

    &.form-control__field-copy{
      .btn{
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: calc(50% - 18px);
      }
    }

    &-flag{
      position: absolute;
      width: 95px;
      height: 100%;

      .bootstrap-select .dropdown-menu .icon{
        border-color: transparent;
        padding: 6px 0;
        width: 23px;
      }

      .form-control.btn{
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        padding: 0 25px !important;
        border-right: 0;

        &:before{
          @include pseudo();
          width: 1px;
          height: 24px;
          top: 50%;
          right: 0;
          margin-top: -12px;
          background: $input-border;
        }

        .filter-option-inner{
          height: 100%;
        }
        .filter-option-inner-inner {
          height: 100%;
          padding: 0;
        }
        .icon{
          padding: 0;
          border: 0;
          margin: 0;
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  &.form-control-phone{
    padding-left: 120px;
  }
  &.btn{
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 400;
    &:focus{
      border-color: $bg-light;
    }

    .bootstrap-select.show &{
      border-color: $bg-light;

      &::after{
        color: $primary;
      }
    }

    .icon{
      width: 48px;
      height: 36px;
      border: 1px solid $input-border;
      border-radius: 2px;
      padding: 6px;
      margin: -.55rem 15px -.5rem 0;
      display: inline-block;
      vertical-align: middle;

      img{
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }
    .filter-option-inner-inner{
      padding-top: .75rem;
      padding-bottom: .75rem;
    }
  }

  &__code{
    display: flex;
    background: #91AAE6;
    border-radius: 10px;

    .form-control--code{
      width: calc(100% / 4);
      text-align: center;
      border: 0;
      background: none;
      height: 80px;
      line-height: normal;
      font-size: 40px;
      font-weight: 300;
      color: $color-base;

      &:-moz-placeholder {
        line-height: 80px;
      }
      &::-moz-placeholder {
        line-height: 80px;
      }

      & + .form-control--code{
        border-left: 1px solid #A4B9ED;
      }

      @include input-placeholder {
        color: $color-base;
      }
      &:hover{
        @include input-placeholder {
          color: $color-base;
        }
      }
    }
  }

  &__search{
    .form-control{
      padding-left: 58px;
      font-size: 15px;
    }
    .form-control__field-icon{
      left: 25px;
      right: auto;
      color: $color-light;
    }
  }

  &.disabled,&:disabled,&[readonly]{
    border-color: $input-border;
    background: $input-border !important;
    color: $color-light;
  }

  &__label{
    font-size: 15px;
    line-height: 1;
    margin-bottom: 15px;
    display: block;
    color: $color-light;
  }

  &__password{
    .form-control__field-icon{
      transition: $transition-base;
      cursor: pointer;
    }
  }

  @media (max-width: 991px){
    &__code{
      border-radius: 5px;

      .form-control--code{
        height: 50px;
        font-size: 20px;

        &:-moz-placeholder {
          line-height: 50px;
        }
        &::-moz-placeholder {
          line-height: 50px;
        }
      }
    }
    &__search{
      .form-control{
        background: #728AC3 !important;
        border-color: #728AC3;
        color: #fff;

        &:focus{
          border-color: $color-base;
        }

        @include input-placeholder {
          color: #fff;
        }
        &:hover{
          @include input-placeholder {
            color: #fff;
          }
        }
      }
      .form-control__field-icon{
        left: 25px;
        right: auto;
        color: #fff;
      }
    }

    &__field{
      &.form-control__field-copy{
        border: 1px solid $input-border;
        border-radius: 5px;
        padding: 3px;
        .form-control{
          border: 0;
        }
        .btn{
          position: relative;
          right: 0;
          top: 0;
          transform: none;
          width: 100%;
        }
      }
    }
  }
  body.day-mode &{
    border-color: $input-day-border;
    color: $color-day-base;

    &:focus{
      border-color: $color-day-base;
      color: $color-day-base;
    }

    &__field{
      position: relative;

      &-icon{
        color: $input-day-border;
        &:hover,&.active{
          color: $primary;
        }
      }
    }

    &__search{
      .form-control__field-icon{
        color: $color-day-lighter;
      }
    }

    &.disabled,&:disabled,&[readonly]{
      border-color: $input-day-border;
      background: $input-day-border !important;
      color: $color-day-lighter;
    }

    &__label{
      color: $color-day-lighter;
    }
  }
}
.custom-control{
  font-size: $input-btn-font-size * 1.0625;
  padding-left: 35px;
  &-label{
    display: block;
    &::before{
      border-radius: 4px;
    }
    &::before,&::after{
      left: -35px;
      top: 2px;
      width: 20px;
      height: 20px;

    }
  }
  &-input{
    width: 20px;
    height: 22px;
  }
}
.alert{
  border-radius: 5px;
  font-size: $font-size-base * .9375;
}

.table{
  font-size: 14px;
  margin: 0;

  &.table-fixed{
    table-layout: fixed;

    td:nth-child(1){
      width: 210px;
    }

    .path-item{
      max-width: calc(100% - 35px);
    }
  }
  .btn-tr.active .icon {
    transform: rotate(45deg);
  }

  thead{
    th{
      border-top: 0;
      border-bottom: 1px solid $input-border;
      vertical-align: middle;
    }
  }

  th{
    border: 1px solid $input-border;
    color: $color-base;
    font-weight: 400;
    padding: 11px 32px;
    height: 51px;
    font-size: 13px;

    &:first-child{
      border-left: 0;
    }
    &:last-child{
      border-right: 0;
    }
  }
  td{
    color: $color-light;
    padding: 11px 32px;
    height: 51px;
    border: 1px solid $input-border;
    vertical-align: middle;
    border-bottom: 0;

    &:first-child{
      border-left: 0;
    }
    &:last-child{
      border-right: 0;
    }

    * + .btn-icon{
      margin-left: 10px;
    }
  }

  tr{
    &:first-child{
      td{
        border-top: 0;
      }
    }
    &:last-child{
      td{
        border-bottom: 0;
      }
    }

    &.active{
      background: rgba($input-border,.3);
    }

    &.table-tr-hidden{
      display: none;

      ul{
        list-style: none;
        margin: 0;
        padding: 0;

        b{
          font-weight: 400;
          min-width: 150px;
          padding-right: 10px;
          display: inline-block;
          vertical-align: top;
        }
      }

      &.active{
        display: table-row;
      }

      &:last-child{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  &.table-borderless{
    tr{
      td,th{
        border-left: 0;
        border-right: 0;
        color: $color-base;
      }
    }
  }

  .text-wrap{
    min-width: 400px;
  }


  &.table--list{
    .btn-icon__txt{
      display: none;
    }
  }
  &.table--peers{
    .btn-icon__txt{
      display: none;
    }
  }
  &.table--sync-path{
    .path-item{
      white-space: normal;
      height: 42px;
      margin: -4px 0;
    }
  }
  @media (min-width: 992px){
    &.table--sync{
      .path-item{
        white-space: normal;
        height: 42px;
        margin: -4px 0;
      }
    }
    &.table--monitoring{
      td{
        width: 25%;
        &:last-child{
          width: 200px;
        }
      }
    }
    &.table--backup{
      td{
        width: 33%;
        &:last-child{
          width: 260px;
        }
      }
    }

  }
  &--tickets{
    &__number{
      position: absolute;
      background: $primary;
      color: #fff;
      border-radius: 9px;
      height: 18px;
      padding: 0 4px;
      text-align: center;
      min-width: 18px;
      font-size: 9px;
      line-height: 18px;
      top: 50%;
      margin-top: -9px;
      left: -9px;
    }

    .link-icon {
      width: 32px;
      height: 32px;
      color: $primary;
      display: block;
      margin: 0 auto;

      &:hover{
        color: darken($primary,10%);
      }
    }
  }
  &.table--tickets{
    color: $color-base;
    background: $bg-base;

    th{
      border: 0;
      background: $bg-light;

      &:first-child{
        border-bottom-left-radius: 10px;
      }
      &:last-child{
        border-bottom-right-radius: 10px;
      }
    }

    td{
      border: 0;
      background: $bg-light;
      color: $color-base;
      position: relative;

      &:first-child{
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
      &:last-child{
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }

      small{
        color: $color-light;
        display: block;
      }
    }

    tr.table--tickets__empty{
      td{
        background: none;
        height: 20px;
      }
    }
    .link-dots__txt{
      display: none;
    }

    @media (min-width: 992px){
      body.day-mode &{
        background: none;
        tr.table--tickets__empty{
          td{
            background: none;
          }
        }
        th,td{
          background: $bg-day-light;
        }
        .link-icon {
          color: $primary;

          &:hover{
            color: darken($primary,10%);
          }
        }
      }
    }
  }
  &--mobile{
    &__btn{
      display: none;
    }
  }
  @media (max-width: 991px){
    &.table--peers{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }
      }
      thead{
        display: none;
      }

      td{
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }
        &:first-child {
          padding: 0;
          border: 0;
          position: absolute;
          top: 0;
          left: 0;

          &::before{
            display: none;
          }

          .tag-online{
            position: absolute;
            top: 16px;
            left: -8px;
          }
        }
        &:nth-child(2),&:nth-child(3),&:nth-child(7){
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          &::before {
            content: attr(data-table-title);
            margin-right: 20px;
          }
        }
        &:nth-child(2){
          width: 55%;
          border-left: 0;
        }
        &:nth-child(3){
          width: 45%;
          border-right: 0;
          border-left: 0;
        }
        &:nth-child(7){
          width: 100%;
          border-left: 0;
          border-right: 0;
          justify-content: space-between;
        }
        &:nth-child(5){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
          &::before {
            margin-bottom: 10px;
          }
        }
        &:nth-child(8),&:nth-child(4){
          width: 100%;
          border-left: 0;
          border-right: 0;
        }
        &:nth-child(6){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        &:nth-child(8){
          display: block;
          padding: 0;
          border: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:nth-child(4),&:nth-child(6){
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &:nth-child(6){
          display: block;
        }
        .btn-icon{
          width: 100%;
          height: 51px;
          line-height: 49px;
          text-align: center;
          padding: 0;
          display: block;
          font-weight: 500;
          font-size: 16px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          box-shadow: none;
          background: $primary;
          color: #fff;
          &__txt{
            display: block;
          }
          .icon{
            display: none;
          }
        }
        .btn-tooltip{
          display: none;
        }
      }

      .main-item__table-pull{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        width: 100%;
        border: 0 !important;
        order: 2;
        font-size: 16px;
        background: $primary;
        color: $color-base;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: $transition-base;

        &:after{
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          margin-left: 15px;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          background: $input-border;
          color: $color-light;

          &:after{
            transform: rotate(-135deg);
            margin-top: 3px;
          }
        }
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
        &::before{
          display: none;
        }
      }
    }
    &.table--search{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;

        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }
      }
      thead{
        display: none;
      }

      td{
        order: 0;
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }
        &:first-child {
          padding: 0;
          border: 0;
          position: absolute;
          top: 0;
          left: 0;

          &::before{
            display: none;
          }

          .tag-online{
            position: absolute;
            top: 16px;
            left: -8px;
          }
        }
        &:nth-child(2),&:nth-child(3){
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          &::before {
            content: attr(data-table-title);
            margin-right: 20px;
          }
        }
        &:nth-child(2){
          width: 55%;
          border-left: 0;
        }
        &:nth-child(3){
          width: 45%;
          border-right: 0;
          border-left: 0;
        }
        &:nth-child(5){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
          &::before {
            margin-bottom: 10px;
          }
        }
        &:nth-child(8),&:nth-child(4){
          width: 100%;
          border-left: 0;
          border-right: 0;
        }
        &:nth-child(6){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        &:nth-child(4),&:nth-child(6),&:nth-child(8){
          order: 1;
          background: #292929;
          display: none;
        }
        &:nth-child(8),&:nth-child(4){
          &.active-mobile{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        &:nth-child(6){
          &.active-mobile{
            display: block;
          }
        }
        .btn-tooltip{
          display: none;
        }
      }

      .main-item__table-pull{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        width: 100%;
        border: 0 !important;
        order: 2;
        font-size: 16px;
        background: $primary;
        color: $color-base;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: $transition-base;

        &:after{
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          margin-left: 15px;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          background: $input-border;
          color: $color-light;

          &:after{
            transform: rotate(-135deg);
            margin-top: 3px;
          }
        }
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        &::before{
          display: none;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
      }
    }
    &.table--sync{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-top: 45px;
        border-radius: 10px;
        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-top: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }

        &.active{
          .main-item__table-pull{
            display: none;
          }
        }

        &.table-tr-hidden {
          margin-top: 0;
          display: none;
          border-radius: 0;

          td{
            display: block;
            height: unset;
            width: 100%;
            white-space: normal;
          }

          &.active{
            display: block;
          }
        }
      }
      thead{
        display: none;
      }

      td{
        order: 1;
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }

        &:nth-child(1),&:nth-child(5){
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          order: 0;
          &::before {
            content: attr(data-table-title);
            margin-right: 20px;
          }
        }
        &:nth-child(1){
          width: 55%;
          border-left: 0;
        }
        &:nth-child(5){
          width: 45%;
          border-right: 0;
          border-left: 0;
        }
        &:nth-child(2){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
          &::before {
            margin-bottom: 10px;
          }
        }
        &:nth-child(3),&:nth-child(4),&:nth-child(7),&:nth-child(9){
          width: 100%;
          border-left: 0;
          border-right: 0;
        }
        &:nth-child(6){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        &:nth-child(3),&:nth-child(4),&:nth-child(7),&:nth-child(6),&:nth-child(9){
          background: #292929;
          display: none;
        }
        &:nth-child(3),&:nth-child(4),&:nth-child(7),&:nth-child(9){
          &.active-mobile{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        &:nth-child(9){
          height: auto;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        &:nth-child(6){
          &.active-mobile{
            display: block;
          }
        }
        .btn-tooltip{
          display: none;
        }
      }

      .main-item__table-pull{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        width: 100%;
        border: 0 !important;
        order: 2;
        font-size: 16px;
        background: $primary;
        color: $color-base;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: $transition-base;

        &:after{
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          margin-left: 15px;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          background: $input-border;
          color: $color-light;

          &:after{
            transform: rotate(-135deg);
            margin-top: 3px;
          }
        }
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        &::before{
          display: none;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
      }
    }
    &.table--logs{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;
        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }

        &.active{
          .main-item__table-pull{
            display: none;
          }
        }

        &.table-tr-hidden {
          margin-top: -45px;
          display: none;
          border-radius: 0;

          td{
            display: block;
            height: unset;
            width: 100%;
            white-space: normal;
          }

          &.active{
            display: block;
          }
        }
      }
      thead{
        display: none;
      }

      td{
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }

        &:nth-child(1),&:nth-child(2){
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          &::before {
            content: attr(data-table-title);
            margin-right: 20px;
          }
        }
        &:nth-child(1){
          width: 55%;
          border-left: 0;
        }
        &:nth-child(2){
          width: 45%;
          border-right: 0;
          border-left: 0;
        }
        &:nth-child(3){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
          &::before {
            margin-bottom: 10px;
          }
        }
        &:nth-child(4),&:nth-child(5),&:nth-child(7){
          width: 100%;
          border-left: 0;
          border-right: 0;
        }
        &:nth-child(5){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
        }
        &:nth-child(4),&:nth-child(5),&:nth-child(7){
          background: #292929;
          display: none;
        }
        &:nth-child(4),&:nth-child(7){
          &.active-mobile{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        &:nth-child(5){
          &:before{
            margin-bottom: 10px;
          }
          &.active-mobile{
            display: block;
          }
        }
        .btn-tooltip{
          display: none;
        }
      }

      .main-item__table-pull{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        width: 100%;
        border: 0 !important;
        order: 2;
        font-size: 16px;
        background: $primary;
        color: $color-base;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: $transition-base;

        &:after{
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          margin-left: 15px;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          background: $input-border;
          color: $color-light;

          &:after{
            transform: rotate(-135deg);
            margin-top: 3px;
          }
        }
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        &::before{
          display: none;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
      }
    }
    &.table--server{
      display: block;
      tbody{
        display: block;

        tr{
          &:last-child{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 1px solid $input-border;
          border-bottom: 0;
        }
      }
      thead{
        display: block;

        th{
          display: none;
          border-bottom: 0;

          &:nth-child(2){
            width: 117px;
            text-align: center;
            display: flex;
            border-left: 0;
            border-right: 0;
            align-items: center;
            justify-content: center;
          }
          &:nth-child(3){
            width: calc(100% - 117px);
            display: flex;
            border-right: 0;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      td{
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }
        &:first-child {
          padding: 0;
          border: 0;
          position: absolute;
          top: 0;
          left: 0;

          &::before{
            display: none;
          }

          .tag-online{
            position: absolute;
            top: 16px;
            left: -8px;
          }
        }
        &:nth-child(2),&:nth-child(3){
          display: flex;
          align-items: center;
          &::before {
            display: none;
          }
        }
        &:nth-child(2){
          width: 117px;
          border-right: 0;
          border-left: 0;
          justify-content: center;
        }
        &:nth-child(3){
          width: calc(100% - 117px);
          border-right: 0;
          justify-content: flex-start;
        }

        &:nth-child(4),&:nth-child(5){
          background: #292929;
          display: none;
          border-left: 0;
          border-right: 0;
          flex-grow: 1;
          &:before{
            margin-right: 20px;
          }
        }
        &:nth-child(4),&:nth-child(5){
          &.active-mobile{
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .table--server__pull{
        position: relative;

        &:after{
          @include pseudo();
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          right: 25px;
          top: 50%;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          &:after{
            transform: rotate(-135deg);
            border-color: $color-base;
            margin-top: 0;
          }
        }
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        &::before{
          display: none;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
      }
    }
    &.table--status{
      display: block;
      tbody{
        display: block;

        tr{
          &:last-child{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          td{
            border-top: 0;
          }
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 1px solid $input-border;
          border-bottom: 0;
        }
      }
      thead{
        display: none;
      }
      td{
        &:nth-child(1){
          border-right: 0;
          border-left: 0;
          width: calc(100% - 110px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $color-base;
        }
        &:nth-child(2){
          width: 110px;
          border-right: 0;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.table--status__pull{
          width: 100%;
          position: relative;
          &:after{
            @include pseudo();
            width: 6px;
            height: 6px;
            border: 1px solid $color-light;
            transform: rotate(45deg);
            right: 50px;
            top: 50%;
            border-top: 0;
            border-left: 0;
            margin-top: -3px;
            transition: $transition-base;
          }

          &.active-mobile{
            &:after{
              transform: rotate(-135deg);
              border-color: $color-base;
              margin-top: 0;
            }
          }
        }

        &.table--status__hidden{
          background: #292929;
          display: none;
          border-left: 0;
          border-right: 0;
          width: 100%;
          height: unset;
          text-align: left;
          padding-top: 20px;
          padding-bottom: 20px;
          &.active-mobile{
            display: block;
          }
        }
      }
      .btn-tooltip{
        display: none;
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 0;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        &::before{
          display: none;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
      }
    }
    &.table--list{
      display: block;
      tbody{
        display: block;

        tr{
          &:last-child{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 1px solid $input-border;
          border-bottom: 0;
        }

        &.active-mobile{
          & + tr{
            margin-top: 45px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            td:nth-child(3),td:nth-child(2){
              border-top-color: transparent;
            }
          }
        }
      }
      thead{
        display: block;

        th{
          display: none;
          border-bottom: 0;

          &:nth-child(2){
            width: 117px;
            text-align: center;
            display: flex;
            border-left: 0;
            border-right: 0;
            align-items: center;
            justify-content: center;
          }
          &:nth-child(3){
            width: calc(100% - 117px);
            display: flex;
            border-right: 0;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      td{
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }
        &:first-child {
          padding: 0;
          border: 0;
          position: absolute;
          top: 0;
          left: 0;

          &::before{
            display: none;
          }

          .tag-online{
            position: absolute;
            top: 16px;
            left: -8px;
          }
        }
        &:nth-child(2),&:nth-child(3){
          display: flex;
          align-items: center;
          &::before {
            display: none;
          }
        }
        &:nth-child(2){
          width: 117px;
          border-right: 0;
          border-left: 0;
          justify-content: center;
        }
        &:nth-child(3){
          width: calc(100% - 117px);
          border-right: 0;
          justify-content: flex-start;
        }
        &:nth-child(6){
          width: 100%;
          border-right: 0;
          border-left: 0;
          height: unset;
          line-height: 1;
          padding-top: 20px;
          padding-bottom: 20px;
          background: #292929;
          display: none;
          &.active-mobile{
            display: block;
          }
        }
        &:nth-child(4),&:nth-child(5),&:nth-child(7),&:nth-child(8){
          background: #292929;
          display: none;
          border-left: 0;
          border-right: 0;
          flex-grow: 1;
          &:before{
            margin-right: 20px;
          }
        }
        &:nth-child(4),&:nth-child(5),&:nth-child(7),&:nth-child(8){
          &.active-mobile{
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }

        &.table--list__btn{
          border: 0;
          display: none;
          width: 100%;
          padding: 0;
          background: $bg-base;
          &.active-mobile{
            display: block;
          }
        }
      }
      .btn-icon{
        width: 100%;
        height: 51px;
        line-height: 49px;
        text-align: center;
        padding: 0;
        display: block;
        font-weight: 500;
        font-size: 16px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &__txt{
          display: block;
        }
        .icon{
          display: none;
        }
      }
      .btn-tooltip{
        display: none;
      }
      .table--list__pull{
        position: relative;

        &:after{
          @include pseudo();
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          right: 25px;
          top: 50%;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          &:after{
            transform: rotate(-135deg);
            border-color: $color-base;
            margin-top: 0;
          }
        }
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        &::before{
          display: none;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
      }
    }
    &.table--monitoring{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }
      }
      thead{
        display: none;
      }

      td{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-light;
        }

        &:last-child{
          height: unset;
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 0;
          .btn{
            display: block;
            width: 100%;
          }
        }

      }
    }
    &.table--backup{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }
      }
      thead{
        display: none;
      }

      td{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-light;
        }

        &:nth-child(2){
          height: unset;
          padding-top: 18px;
          padding-bottom: 17px;
          flex-wrap: wrap;
          line-height: 1;
          word-break: break-word;
          white-space: normal;
          &::before {
            margin-bottom: 10px;
            width: 100%;
          }

        }

        &:last-child{
          height: unset;
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 0;
          .btn{
            flex-grow: 1;
          }
        }

      }
    }
    &.table-a-logs{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }
      }
      thead{
        display: none;
      }

      td{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        min-width: unset;
        border-left: 0;
        border-right: 0;
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }

        &:nth-child(2){
          height: unset;
          padding-top: 18px;
          padding-bottom: 17px;
          flex-wrap: wrap;
          line-height: 1;
          word-break: break-word;
          white-space: normal;
          &::before {
            margin-bottom: 10px;
            width: 100%;
          }

        }

        &:last-child{
          height: unset;
          padding-top: 18px;
          padding-bottom: 17px;
          flex-wrap: wrap;
          line-height: 1;
          word-break: break-word;
          white-space: normal;
          border-bottom: 0;
          &::before {
            margin-bottom: 10px;
            width: 100%;
          }
        }

      }
    }
    &--tickets{
      &__number{
        position: absolute;
        background: $primary;
        color: #fff;
        border-radius: 9px;
        height: 18px;
        padding: 0 4px;
        text-align: center;
        min-width: 18px;
        font-size: 9px;
        line-height: 18px;
        top: 50%;
        margin-top: -9px;
        left: -9px;
      }

      .link-icon {
        width: 32px;
        height: 32px;
        color: $primary;
        display: block;
        margin: 0 auto;

        &:hover{
          color: darken($primary,10%);
        }
      }
    }
    &.table--tickets{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          & + tr{
            td{
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;

          &:first-child{
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }

          &:last-child{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
      thead{
        display: none;
      }

      td{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        min-width: unset;
        border-left: 0;
        border-right: 0;
        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-light;
        }

        &:nth-child(3){
          height: unset;
          padding-top: 18px;
          padding-bottom: 17px;
          flex-wrap: wrap;
          flex-direction: column;
          line-height: 1;
          word-break: break-word;
          white-space: normal;
          align-items: flex-start;

          small{
            display: none;
          }
          &::before {
            margin-bottom: 10px;
            width: 100%;
            text-align: left;
          }

        }

        &:last-child{
          height: unset;
          padding: 0;
          flex-wrap: wrap;
          line-height: 1;
          word-break: break-word;
          white-space: normal;
          border-bottom: 0;
          &::before {
            display: none;
          }
          .link{
            height: 50px;
            line-height: 50px;
            width: 100%;
            display: block;
            color: #fff;
            background: $primary;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;

            &:hover{
              color: #fff;
              background: darken($primary,10%);
            }

            .icon{
              display: none;
            }

            .link-dots__txt{
              display: block;
            }
          }
        }

      }

      tr.table--tickets__empty{
        display: none;
      }
    }
    &.table--mobile{
      display: none;

      &.active-mobile{
        display: table;
      }

      td:first-child{
        border-left: 0;
        border-right: 0;
        padding-right: 10px;
        white-space: nowrap;
      }
      td:last-child{
        border-left: 0;
        border-right: 0;
        padding-left: 10px;
        text-align: right;
        color: $color-base;
        word-break: break-word;
        white-space: normal;
      }
    }
    &--mobile{
      &__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        width: 100%;
        border: 0 !important;
        order: 2;
        font-size: 16px;
        background: $primary;
        color: $color-base;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: $transition-base;
        height: 50px;
        line-height: 50px;

        &:hover{
          color: $color-base;
        }

        &:after{
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          margin-left: 15px;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          background: $input-border;
          color: $color-light;

          &:after{
            transform: rotate(-135deg);
            margin-top: 3px;
          }
        }
      }
    }
    &.table--billing{
      display: block;
      tbody{
        display: block;
      }
      tr{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 45px;
        border-radius: 10px;

        &:first-child{
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }

        td{
          border-top: 0;
          border-bottom: 1px solid $input-border;
        }
      }
      thead{
        display: none;
      }

      td{
        width: 100%;
        display: none;
        align-items: center;
        justify-content: space-between;
        border-left: 0;
        border-right: 0;

        &.active-mobile{
          display: flex;
        }

        &::before {
          content: attr(data-table-title);
          font-size: 13px;
          display: block;
          color: $color-base;
        }
        &:nth-child(1){
          border-right: 1px solid $input-border;
        }
        &:nth-child(1),&:nth-child(2){
          display: flex;
          width: auto;
          flex-grow: 1;
        }
        &:nth-child(6){
          flex-direction: column;
          height: auto;
          align-items: flex-start;
          &::before {
            content: attr(data-table-title);
            width: 100%;
            margin-bottom: 5px;
          }
        }
        &:nth-child(9){
          padding-right: 16px;
        }
        &:nth-child(10){
          padding-left: 16px;
        }
        &:nth-child(9), &:nth-child(10){
          width: 50%;
          height: auto;
          padding-top: 20px;
          padding-bottom: 20px;

          .btn{
            width: 100%;
          }
        }
      }

      td.main-item__table-pull{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        width: 100%;
        border: 0 !important;
        order: 2;
        font-size: 16px;
        background: $primary;
        color: $color-base;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: $transition-base;

        &:after{
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 6px;
          height: 6px;
          border: 1px solid;
          transform: rotate(45deg);
          margin-left: 15px;
          border-top: 0;
          border-left: 0;
          margin-top: -3px;
          transition: $transition-base;
        }

        &.active-mobile{
          background: $input-border;
          color: $color-light;

          &:after{
            transform: rotate(-135deg);
            margin-top: 3px;
          }
        }
      }
      .database-item,.path-item{
        max-width: 100%;
        overflow: auto;
        white-space: nowrap;
        text-overflow: unset;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        background: none;
        color: inherit;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        scrollbar-color: $input-border $primary;
        scrollbar-width: thin;
        scrollbar-height: thin;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $input-border;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: $primary;
        }
        &::before{
          display: none;
        }
      }
    }
  }
  @media (max-width: 413px){
    th,td{
      padding-left: 25px;
      padding-right: 25px;
    }
    &.table--peers,
    &.table--search,
    &.table--sync,
    &.table--logs,
    &.table--server,
    &.table--status,
    &.table--list,
    &.table--monitoring,
    &.table--backup,
    &.table-a-logs,
    &.table--billing{
      td,th{
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
  @media (max-width: 374px){
    font-size: 13px;
    th,td{
      padding-left: 15px;
      padding-right: 15px;
    }
    &.table--peers,
    &.table--search,
    &.table--sync,
    &.table--logs,
    &.table--server,
    &.table--status,
    &.table--list,
    &.table--monitoring,
    &.table--backup,
    &.table-a-logs,
    &.table--billing{
      td,th{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  body.day-mode &{
    color: $color-day-lighter;

    thead{
      th{
        border-color:$input-day-border;
      }
    }

    tr{
      &.active{
        background: #E9E9EE;
      }
    }

    th{
      border-color:$input-day-border;
      color: $color-day-base;
    }
    td{
      color: $color-day-lighter;
      border-color:$input-day-border;
    }
    &.table-borderless{
      tr{
        td,th{
          color: $color-day-base;
        }
      }
    }
  }

  @media (max-width: 991px){
    body.day-mode &{
      &.table--peers,&.table--search,&.table--sync,&.table--logs,&.table--server,&.table--status,&.table--list,&.table--monitoring,&.table--backup, &.table-a-logs,&.table--tickets,&.table--billing{
        tr{
          td{
            border-color: $input-day-border;
          }
        }
        td{
          &::before {
            color: $color-day-base;
          }
        }
        .main-item__table-pull{
          background: $primary;
          color: $color-base;
          &.active-mobile{
            background: $input-day-border;
            color: $color-day-lighter;
          }
        }
        .database-item,.path-item{
          scrollbar-color: #E9E9EE $primary;
          background: 0 0;
          color: inherit;
          -webkit-background-clip: unset;
          -webkit-text-fill-color: unset;
          &::-webkit-scrollbar-track {
            background-color: #E9E9EE;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $primary;
          }
        }
      }
      &.table--search{
        td{
          &:nth-child(4),&:nth-child(6),&:nth-child(8){
            background: $bg-day-light;
          }
        }
      }
      &.table--sync{
        td{
          &:nth-child(3),&:nth-child(4),&:nth-child(7),&:nth-child(6),&:nth-child(9){
            background: $bg-day-light;
          }
        }
      }
      &.table--logs{
        td{
          &:nth-child(4),&:nth-child(5),&:nth-child(7){
            background: $bg-day-light;
          }
        }
      }
      &.table--server{
        td{
          &:nth-child(4),&:nth-child(5){
            background: $bg-day-light;
          }
        }
        .table--server__pull{
          &.active-mobile{
            &:after{
              border-color: $color-day-base;
            }
          }
        }
      }
      &.table--status{
        td{
          &:nth-child(1){
            color: $color-day-base;
          }
          &.table--status__pull{
            &:after{
              border-color: $color-day-lighter;
            }

            &.active-mobile{
              &:after{
                border-color: $color-day-base;
              }
            }
          }

          &.table--status__hidden{
            background: $bg-day-light;
          }
        }
      }
      &.table--list{
        td{
          &:nth-child(6){
            background: $bg-day-light;
          }
          &:nth-child(4),&:nth-child(5),&:nth-child(7),&:nth-child(8){
            background: $bg-day-light;
          }
          &.table--list__btn{
            background: $bg-day-light;
          }
        }
        .table--list__pull{
          &.active-mobile{
            &:after{
              border-color: $color-day-base;
            }
          }
        }
      }
      &.table--tickets{
        background: none;

        tr{
          background: none;
        }
        td{
          background: $bg-day-light;
        }
      }
      &.table--billing{
        td{
          &:nth-child(1){
            border-color: $input-day-border;
          }
        }
      }
    }
  }
}

.badge{
  text-transform: uppercase;
  line-height: 1;
  border-radius: 3px;
  background: $input-border;
  padding: 8px 7px;

  &.badge-sm{
    font-size: 14px;
    padding: 10px 22px;
    border-radius: 4px;
    text-transform: none;
  }

  &.badge-md{
    font-size: 13px;
    padding: 12px 25px;
    border-radius: 19px;
  }
  &.badge-outline{
    border: 1px solid $input-border;
    padding: 0 16px;
    border-radius: 18px;
    font-size: 13px;
    text-transform: none;
    padding: 11px 16px;
  }
  &.badge-in{
    border: 1px solid $success;
    color: $success;
    border-radius: 18px;
    font-size: 13px;
    text-transform: none;
    padding: 11px 5px;
    min-width: 37px;
    text-align: center;
    background: none;
  }
  &.badge-out{
    border: 1px solid $danger;
    color: $danger;
    border-radius: 18px;
    font-size: 13px;
    text-transform: none;
    padding: 11px 5px;
    min-width: 37px;
    text-align: center;
    background: none;
  }

  &-danger{
    color: $danger;
  }
  &-warning{
    color: $warning;
  }
  &-info{
    color: $info;
  }
  &-success{
    color: $success;
  }
  &-primary{
    color: $primary;
  }
  &-secondary{
    color: $color-light;
  }

  &-trust{
    min-width: 70px;
    text-align: center;

    &-number{
      min-width: 35px;
    }
    &-line{
      position: relative;
      min-width: 10px;
      background: $input-border;
      border-radius: 4px;
      height: 4px;
      overflow: hidden;
      flex-grow: 1;
      display: inline-block !important;
      padding: 0;
      margin: 0;

      &:before{
        @include pseudo();
        top: 0;
        left: 0;
        bottom: 0;
        background: $primary;
      }
    }
    &-100{
      color: $warning;
      &:before{
        width: 100%;
      }
    }
    &-80{
      color: $indigo;
      &:before{
        width: 80%;
      }
    }
    &-60{
      color: $info;
      &:before{
        width: 60%;
      }
    }
    &-40{
      color: $success;
      &:before{
        width: 40%;
      }
    }
    &-20{
      color: $color-light;
      &:before{
        width: 20%;
      }
    }
    &-0{
      color: $danger;
      &:before{
        width: 0%;
      }
    }
    &-blocked{
      color: $color-disabled;
    }

    &-circle{
      &__chart {
        display: block;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        &-bg{
          fill: none;
          stroke: #353535;
          stroke-width: 3.8;
        }
        &-line{
          fill: none;
          stroke-width: 2.8;
          stroke-linecap: round;
          stroke: $primary;
          animation: progress 1s ease-out forwards;
        }
      }

      @keyframes progress {
        0% {
          stroke-dasharray: 0 100;
        }
      }

    }
  }

  body.day-mode &{
    background: #D6D6E0;

    &.badge-outline{
      border-color: $input-day-border;
    }
    &.badge-in,&.badge-out{
      background: none;
    }

    &-secondary{
      color: $color-day-lighter;
    }

    &-trust{
      min-width: 70px;
      text-align: center;

      &-number{
        min-width: 35px;
      }
      &-line{
        background: #D6D6E0;
      }

      &-20{
        color: $color-day-lighter;
      }

      &-blocked{
        color: $color-day-light;
      }
    }
  }
}

.dropdown-toggle{
  &::after{
    width: 5px;
    height: 5px;
    border: 1px solid;
    transform: rotate(45deg);
    border-top: 0;
    border-left: 0;
    color: $input-border;
    transition: $transition-base;
  }
  &:hover,&:focus{
    &::after{
      color: $primary;
    }
  }
}
.dropup .dropdown-toggle::after {
  transform: rotate(-135deg);
  border: 1px solid;
  border-top: 0;
  border-left: 0;
}
.bootstrap-select .dropdown-menu.inner{
  li + li{
    border-top: 1px solid $input-border;
  }
}

.list-group{
  font-size: 18px;
  font-weight: 500;

  &-item{
    border-left: 0;
    border-right: 0;
    min-height: 75px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    &:first-child{
      border-top: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child{
      border-bottom: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  &.list-group--menu{
    max-width: 333px;
    margin-bottom: 36px;

    .list-group-item{
      position: relative;
      &:last-child{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:before{
        @include pseudo();
        border: 2px solid;
        border-left: 0;
        border-top: 0;
        width: 8px;
        height: 8px;
        transform: rotate(-45deg);
        right: 30px;
        top: 50%;
        margin-top: -3px;
      }
    }
  }
}

article{
  color: $color-light;
  line-height: 1.625;

  h1,h2,h3,h4,h5,h6{
    color: $color-base;
    font-family: $headings-font-family;
  }
  h1,h2,h3{
    font-weight: 700;
    font-size: 36px;
    margin: 55px 0 25px;

    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  p,ul,li{
    margin: 25px 0;

    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  li {
    padding-left: 5px;
  }
  ul {
    padding-inline-start: 18px;
  }

  li::marker{
    color: $primary;
  }
}

.pagination{
  .page-item{
    margin: 0 10px;
  }
  .page-link{
    border-radius: 3px;
    padding: 0 5px;
    text-align: center;
    min-width: 40px;
    height: 40px;
    line-height: 38px;

    .icon{
      width: 12px;
      height: 12px;
      vertical-align: baseline;
    }
  }
  body.day-mode &{
    .page-item{
      &.disabled {
        .page-link {
          background-color: #D9DAE3;
          border-color: #D9DAE3;
          color: $color-day-light;
        }
      }
      &.active {
        .page-link {
          background-color: $primary;
          border-color: $primary;
          color: #fff;
        }
      }
    }
    .page-link{
      background-color: #D9DAE3;
      border-color: #D9DAE3;
      color: $color-day-light;

      &:hover{
        background-color: $primary;
        border-color: $primary;
        color: #fff;
      }
    }
  }

  @media (max-width: 735px) {
    .page-item{
      display: none;

      &:first-child,&:last-child{
        display: block;
      }
    }
  }
}

.swiper-pagination-bullet{
  &:focus{
    outline: none;
  }
}

canvas{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  color: white;
  border-radius: 5px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 10px 15px !important;
  z-index: 10;
  white-space: nowrap;

  thead{
    display: none;
  }

  td{
    padding: 4px;
  }
  body.day-mode &{
    background: rgba(#C9CCD7,.75);
  }
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.chartjs-tooltip-sub{
  opacity: .6;
}

.modal{
  &-dialog {
    margin-top: 95px;
    margin-bottom: 30px;
    min-height: calc(100% - 125px);
  }
  &-backdrop{
    background: #222222;

    &.fade {
      opacity: 0;
    }
    &.show {
      opacity: .9;
    }
  }
  &-content,&-header{
    position: relative;
    .close{
      width: 54px;
      height: 54px;
      padding: 17px;
      border-radius: 50%;
      margin: 0;
      position: absolute;
      bottom: 100%;
      right: 0;
      margin-bottom: 32px;
      background: $primary;
      color: rgba(#fff,.5);
      opacity: 1 !important;

      .icon{
        display: block;
        width: 100%;
        height: 100%;
      }

      &:focus{
        outline: none;
      }

      &:hover{
        color: #fff;
        background: $primary;
        opacity: 1 !important;
      }
    }
  }
  &-content {
    background-color: #2C2C2C;
    border: 0;
    border-radius: 10px;

    &.modal-content--rounded{
      border-radius: 30px;
    }
  }
  &-header {
    padding: 0 32px;
    border-bottom: 1px solid $input-border;
    position: relative;
  }
  &-footer{
    padding: 0 32px 32px;
    border: 0;
  }
  &-body {
    padding: 32px;

    .row{
      margin: -10px;
    }
    .col{
      padding: 10px;
    }

    &__content{
      max-width: 290px;
      margin: 0 auto;
      padding: 12px 0;
      text-align: center;

      .btn{
        margin-top: 70px;
      }
      &-icon{
        width: 150px;
        height: 150px;
        background: $input-border;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 32px;
        position: relative;
        margin: 0 auto 50px;

        &.modal-body__content-icon--success{
          &:before{
            @include pseudo();
            background: $primary;
            border: 2px solid #2C2C2C;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            top: 5px;
            right: 11px;
          }
          &:after{
            @include pseudo();
            border: 1px solid #fff;
            width: 11px;
            height: 7px;
            top: 14px;
            right: 20px;
            border-top: 0;
            border-right: 0;
            transform: rotate(-45deg);
          }
        }

        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &-primary{
        display: block;
        color: $primary;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 13px;
      }
      &-title{
        font-weight: 500;
        display: block;
        margin-bottom: 13px;
        font-size: 18px;
        line-height: 1;
      }
      &-subtitle{
        display: block;
        font-size: 14px;
        line-height: 1;
        color: $color-light;
      }
    }
  }
  &-title {
    font-size: 18px;
    margin-bottom: 0;
    line-height: .77;
    padding: 32px 0;
    position: relative;

    &:before{
      @include pseudo();
      bottom: -1px;
      left: 0;
      right: 0;
      height: 3px;
      background: $primary;
    }
  }
  .form-control--modal{
    background: rgba($input-border,.5);
    border-radius: 5px;

    .form-control{
      border: 0 !important;
      padding-left: 32px !important;
      padding-right: 32px !important;
      font-size: 16px;
    }
    .form-control__label{
      line-height: 1;
      margin-bottom: 0;
      padding: 20px 32px 0;
      font-size: 12px;
    }
    .bootstrap-select>.dropdown-toggle:after {
      margin-top: -11px !important;
      color: #999B9F;
    }
    .bootstrap-select.show .form-control.btn::after {
      color: #4772d6;
    }
  }
}

.number-checkbox{
  display: block;
  height: 100%;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  margin: 0;

  &:before{
    @include pseudoHeight(100%);
  }
  
  &__title{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: $color-light;
    border-radius: 10px;
    background: $input-border;
    cursor: pointer;
    transition: $transition-base;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  input{
    display: none;
    
    &:checked + .number-checkbox__title{
      background: $primary;
      color: #fff;
    }
  }
  @media (min-width: 414px){
    &:before{
      @include pseudoHeight(75px);
    }
  }
  @media (max-width: 413px){
    font-size: 16px;
  }
  @media (max-width: 374px){
    font-size: 15px;
  }
}

.form-control__field.form-control__field-b-n{
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .billingValueBtn{
    display: none;
  }

  @media (max-width: 991px){
    position: relative;
    padding: 0 50px;
    .form-control{
      border: 0 !important;
      background: none !important;
      font-size: 36px;
      font-weight: 500;
      text-align: center;
      line-height: 1;
      height: calc(1em + 1.94rem);
    }
    .form-control__field-txt {
      font-size: 38px;
      font-weight: 500;
      right: auto;
      left: 70px;
      opacity: 1;
    }
    .billingValueBtn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: $input-border;
      position: absolute;
      top: 50%;
      margin-top: -20px;
      border-radius: 10px;
      transition: $transition-base;
      padding: 13px;
      color: $primary;

      &.billingValueMinus{
        left: 0;
      }
      &.billingValuePlus{
        right: 0;
      }

      .icon{
        display: block;
        width: 14px;
        height: 14px;
      }

      &:focus{
        outline: none;
      }

      &:active{
        color: #fff;
        background: $primary;
      }
    }
  }
}

.noUi-target {
  background: $input-border;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}
.noUi-connect {
  background: $primary;
}
.noUi-horizontal .noUi-handle {
  width: 26px;
  height: 26px;
  right: -12px;
  top: -12px;
  border: $bg-light;
  box-shadow: none;
  background: $primary;
}
.noUi-handle:after {
  left: 9px;
}
.noUi-handle:before{
  left: 17px;
}

body.day-mode {
  .bootstrap-select .dropdown-menu{
    background: #D6D6E0;
    border-color: #D6D6E0;

    li + li{
      border-color: #D6D6E0;
    }

    a{
      color: $color-day-base;

      &:hover,&.selected{
        color: $color-day-lighter;
      }
    }
  }
  .form-control-with-icon.form-control-lang .icon:before,.form-control__field-flag .form-control.btn:before{
    background: $input-day-border;
  }
  .form-control__field.form-control__field-copy{
    border-color: $input-day-border;
  }
  .wrapper.wrapper-admin{
    .bootstrap-select .dropdown-menu{
      background: #D6D6E0;
      border-color: #D6D6E0;

      li + li{
        border-color: #D6D6E0;
      }

      a{
        color: $color-day-base;

        &:hover,&.selected{
          color: $color-day-lighter;
        }
      }
    }
    .form-control-with-icon.form-control-lang .icon:before,.form-control__field-flag .form-control.btn:before{
      background: $input-day-border;
    }
    .form-control__field.form-control__field-copy{
      border-color: $input-day-border;
    }
  }
  .badge-trust-circle__chart-bg{
    stroke: #D6D6E0;
  }
  .header-admin__profile-hidden{
    background: #D6D6E0;

    a{
      color: $color-day-base;
    }
  }
  .col-form {
    .main-item__content,.main-item__head{
      background-color: transparent;
    }
    .main-item{
      background-color: $bg-day-light;
    }
  }
  .cards-slider__item,.confirmation-slider__item{
    background-color: $bg-day-light;
  }
  .cards-slider__number,.confirmation-slider__rate,.confirmation-slider__item .main-item__head-title-txt p{
    color: $color-day-lighter;
  }
  .cards-slider__title {
    font-weight: 500;
  }
  .cards-slider__rate-item,.confirmation-slider__rate:before{
    background: #D6D6E0;

    &:hover{
      background: $primary;
    }
  }

  .modal{
    &-backdrop{
      background: linear-gradient(140.01deg, rgba(238, 239, 255, 0.6) 30.44%, rgba(230, 231, 255, 0.8) 77.19%);

      &.fade {
        opacity: 0;
      }
      &.show {
        opacity: 1;
      }
    }
    &-content {
      background-color: #fff;
    }
    &-header {
      border-color: $input-day-border;
    }
    .form-control--modal{
      background: none;
      box-shadow: inset 0 0 0 1px #D3D4E2;
    }
    &-body {
      &__content{
        &-icon{
          background: $input-day-border;
          &.modal-body__content-icon--success{
            &:before{
              border-color: #fff;
            }
          }
        }
        &-subtitle{
          color: $color-day-lighter;
        }
      }
    }
  }
  .noUi-target {
    background: $input-day-border;
  }
  .noUi-connect {
    background: $primary;
  }
  .noUi-horizontal .noUi-handle {
    border-color: transparent;
    background: $primary;
  }

  .number-checkbox{
    &__title{
      color: $color-day-lighter;
      background: $input-day-border;
    }
    input{
      &:checked + .number-checkbox__title{
        background: $primary;
        color: #fff;
      }
    }
  }

  .form-control__field.form-control__field-b-n{
    @media (max-width: 991px){
      .billingValueBtn{
        background: $input-day-border;
        color: $primary;
        &:active{
          color: #fff;
          background: $primary;
        }
      }
    }
  }
  .chat-content__topbar-controls-item,.chat-content__topbar-title p,.chat__message-info span{
    color: $color-day-lighter;
  }
  .chat-sidebar{
    background: #CFD0DB;
  }
  .chat__zone,.chat-sidebar__content-wrapper{
    scrollbar-color: #E9E9EE $primary;
    &::-webkit-scrollbar-track {
      background-color: #E9E9EE;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary;
    }
  }
  .chat__message-outbox .chat__message-content-txt{
    background: $bg-day-light;
    color: rgba(44, 48, 73, 0.8);
  }
  .chat__message-content-txt{
    color: #fff;
  }
  .chat-content__topbar{
    border-color: $input-day-border;
  }
  .chat-sidebar__message{
    background: $bg-day-light;
  }
  .chat-sidebar__message-inbox{
    color: $color-day-lighter;
  }
  .chat .form-control__field .form-control {
    background: rgba(255, 255, 255,.3) !important;
  }

  .chat-content{
    &__wrapper{
      background: #CFD0DB;

      @media (min-width: 992px){
        background: none;
      }
    }
  }
}